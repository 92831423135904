<template>
    <div v-loading.fullscreen.lock="m__loading" class="bg-bg">
        <Navbar :total_balance="total_balance" />

        <Sidebar />

        <WarnExpiredPackageDialog
            :current_package="current_package"
            :visible.sync="show_warn_expires_dialog"
        ></WarnExpiredPackageDialog>

        <ConnectTiktokShopDialog
            :visible.sync="show_connect_tiktok_shop_dialog"
        ></ConnectTiktokShopDialog>

        <IntroduceAtosaProjectDialog
            :visible.sync="show_introduce_atodake_dialog"
        ></IntroduceAtosaProjectDialog>

        <main
            id="view_layout"
            class="w-full transition-all h-[calc(100vh-64px)] overflow-auto"
            :style="{ paddingLeft: sidebar_opening ? '204px' : '92px' }"
        >
            <div class="pt-3 pb-4 pl-4 pr-4">
                <router-view />
            </div>
        </main>
    </div>
</template>

<script>
import moment from 'moment'
import Sidebar from './sidebar'
import Navbar from './navbar'
import { getBusinessCenters } from '@/services/atosa-tiktok-ads/business-center'
// import { getTrialPackage } from '@/services/atosa/package'
import { getCurrentPackage } from '@/services/atosa/package'
import { useShepherd } from 'vue-shepherd'
import { getRequestAccessTiktokShop } from '@/services/atosa-tiktok-ads/store'
import { getAdminRole } from '@/services/atosa-tiktok-ads/admin-config'
import WarnExpiredPackageDialog from './warn-expired-package-dialog'
import ConnectTiktokShopDialog from './connect-tiktok-shop-dialog'
import IntroduceAtosaProjectDialog from './introduce-atosa-project-dialog'
import { getAdvertiserAccounts } from '@/services/atosa-tiktok-ads/advertiser-account'
import { getTiktokAccounts } from '@/services/atosa-tiktok-ads/tiktok-account'

export default {
    components: {
        Sidebar,
        Navbar,
        IntroduceAtosaProjectDialog,
        ConnectTiktokShopDialog,
        WarnExpiredPackageDialog
    },

    data() {
        return {
            total_balance: 0,
            tour: null,
            show_introduce_atodake_dialog: false,
            show_connect_tiktok_shop_dialog: false,
            cannot_show_connect_tiktok_shop_dialog: false,
            show_warn_expires_dialog: false,
            current_package: null
        }
    },

    computed: {
        s__channel() {
            return this.$store.getters.app.s__channel
        },

        sidebar_opening() {
            return this.$store.getters.app.s__is_sidebar_opening
        },

        user_info() {
            return this.$store.getters.user.s__user_info
        },

        tiktok_businesses() {
            return this.$store.getters.tiktokBusiness.s__tiktok_businesses
        }
    },

    watch: {
        tiktok_businesses() {
            this.setSelectedTiktokBusiness(this.tiktok_businesses)
        }
    },

    async beforeMount() {
        await this.fetchTiktokBusinesses()

        this.getAdminRole()

        // getTrialPackage()

        this.checkCurrentPackage()

        // this.createTour()
    },

    methods: {
        async getAdminRole() {
            const response = await getAdminRole()
            this.$store.state.user.s__is_admin = !!response.data
        },

        showIntroduceDialog() {
            const temp = localStorage.getItem(
                'checked_never_show_introduce_atodake'
            )
            this.show_introduce_atodake_dialog = `${temp}` !== `1`
        },

        async showConnectTiktokShopDialog() {
            const temp = localStorage.getItem(
                'checked_never_show_connect_tiktok_shop'
            )

            if (temp) {
                return
            }

            try {
                const response = await getRequestAccessTiktokShop()

                if (response.data.length !== 0) {
                    // this.showIntroduceDialog()
                    return
                }
            } catch (error) {
                console.error(error)
            }

            this.show_connect_tiktok_shop_dialog = true
        },

        createTour() {
            this.tour = useShepherd({
                useModalOverlay: true,
                defaultStepOptions: {
                    classes: 'shepherd-theme-custom',
                    scrollTo: true
                }
            })

            this.tour.addStep({
                canClickTarget: true,
                attachTo: { element: '#tg_1', on: 'top' },
                title: 'Test1',
                text: 'Test1',
                buttons: [
                    {
                        text: this.$t('button.close'),
                        action: this.tour.cancel,
                        secondary: true
                    },
                    {
                        text: this.$t('button.next'),
                        action: this.tour.next
                    }
                ]
            })

            this.tour.addStep({
                attachTo: { element: '#tg_2', on: 'top' },
                title: 'Test2',
                text: 'Test2',
                buttons: [
                    {
                        text: this.$t('button.next'),
                        action: this.tour.next
                    }
                ]
            })

            this.tour.addStep({
                attachTo: { element: '#tg_3', on: 'top' },
                title: 'Test3',
                text: 'Test3',
                buttons: [
                    {
                        text: this.$t('button.next'),
                        action: this.tour.next
                    }
                ]
            })

            this.tour.addStep({
                attachTo: { element: '#tg_4', on: 'top' },
                title: 'Test4',
                text: 'Test4',
                buttons: [
                    {
                        text: this.$t('button.next'),
                        action: this.tour.next
                    }
                ]
            })

            this.tour.start()
        },

        async fetchTiktokBusinesses() {
            this.m__loading = true

            try {
                const response = await getTiktokAccounts()

                if (response.code === 0 && response.data.list.length > 0) {
                    const tiktok_businesses = response.data.list
                    await this.$store.dispatch(
                        'tiktokBusiness/s__setTiktokBusinesses',
                        tiktok_businesses
                    )

                    await this.setSelectedTiktokBusiness(tiktok_businesses)
                } else {
                    await this.$store.dispatch(
                        'tiktokBusiness/s__setTiktokBusinesses',
                        []
                    )
                    this.$router.push({
                        path: `${this.p__group_route.ads_manager}/${this.p__route.tiktok_business}/connect`
                    })

                    this.cannot_show_connect_tiktok_shop_dialog = true
                }
            } catch (error) {
                console.error(error)
            }

            this.m__loading = false
        },

        async setSelectedTiktokBusiness(tiktok_businesses) {
            if (tiktok_businesses.length === 0) {
                return
            }

            const id_tiktok_business =
                localStorage.getItem('id_tiktok_business')
            if (!id_tiktok_business) {
                const acc = tiktok_businesses[0]
                localStorage.setItem('id_tiktok_business', acc.id)
                this.$store.dispatch(
                    'tiktokBusiness/s__setSelectedTiktokBusiness',
                    acc
                )
                this.getTotalBalance(acc.id)

                return
            }

            const account = tiktok_businesses.find(
                (item) => item.id === id_tiktok_business
            )
            if (!account) {
                const acc = tiktok_businesses[0]
                localStorage.setItem('id_tiktok_business', acc.id)
                this.$store.dispatch(
                    'tiktokBusiness/s__setSelectedTiktokBusiness',
                    acc
                )
                this.getTotalBalance(acc.id)

                return
            }

            await this.$store.dispatch(
                'tiktokBusiness/s__setSelectedTiktokBusiness',
                account
            )

            this.getTotalBalance(account.id)
        },

        async getTotalBalance(account_id) {
            let total = 0

            const response = await getAdvertiserAccounts(account_id)

            if (response.code === 0 && response.data.length > 0) {
                total = response.data.reduce((acc, current) => {
                    acc += current.balance

                    return acc
                }, 0)
                this.total_balance = this.p__renderMoneyByCurrency(
                    total,
                    response.data[0].currency
                )
            }
        },

        async fetchBusinessCenters() {
            if (
                !this.$store.getters.tiktokBusiness?.s__selected_tiktok_business
            ) {
                return
            }
            const tiktok_account_id =
                this.$store.getters.tiktokBusiness.s__selected_tiktok_business
                    .id
            const response = await getBusinessCenters(tiktok_account_id)

            if (response.code === 0 && response.data.length > 0) {
                await this.$store.dispatch(
                    'businessCenter/s__setBusinessCenters',
                    response.data
                )

                await this.setSelectedBusinessCenter(
                    tiktok_account_id,
                    response.data
                )
            } else {
                await this.$store.dispatch(
                    'businessCenter/s__setBusinessCenters',
                    []
                )
            }
        },

        async setSelectedBusinessCenter(tiktok_account_id, business_centers) {
            if (business_centers.length === 0) {
                return
            }

            const id_bc = localStorage.getItem(`id_bc_${tiktok_account_id}`)

            if (!id_bc) {
                const bc = business_centers[0]
                localStorage.setItem(`id_bc_${tiktok_account_id}`, bc.bc_id)
                await this.$store.dispatch(
                    'businessCenter/s__setSelectedBusinessCenter',
                    bc
                )
                return
            }

            const temp_bc = business_centers.find(
                (item) => item.bc_id === id_bc
            )
            if (!temp_bc) {
                const bc = business_centers[0]
                localStorage.setItem(`id_bc_${tiktok_account_id}`, bc.bc_id)
                await this.$store.dispatch(
                    'businessCenter/s__setSelectedBusinessCenter',
                    bc
                )
                return
            }

            await this.$store.dispatch(
                'businessCenter/s__setSelectedBusinessCenter',
                temp_bc
            )
        },

        async checkCurrentPackage() {
            try {
                const response = await getCurrentPackage(this.s__channel)

                if (response) {
                    if (response.license.length === 0) {
                        this.$router.push({
                            path: `${this.p__group_route.general}/${this.p__route.package}`
                        })
                    } else {
                        const package_data = response.channel_packages.find(
                            (item) => item._id === response.license[0].package
                        )
                        await this.$store.dispatch(
                            'currentPackage/s__setCurrentPackage',
                            {
                                ...response,
                                extra_data: package_data
                                    ? package_data.extra_data
                                    : null
                            }
                        )

                        const license_time = moment(response.license_to * 1000)
                        const expiry = license_time.diff(moment()) > 0
                        if (!expiry) {
                            this.current_package = response

                            // this.show_warn_expires_dialog = true

                            this.$router.push({
                                path: `${this.p__group_route.general}/${this.p__route.package}`
                            })
                        } else {
                            // await this.fetchTiktokBusinesses()

                            await this.fetchBusinessCenters()

                            if (
                                this.user_info &&
                                this.user_info.user.is_manager &&
                                !this.cannot_show_connect_tiktok_shop_dialog
                            ) {
                                await this.showConnectTiktokShopDialog()
                            }
                        }
                    }
                }
            } catch (error) {
                console.error(error)
            }
        }
    }
}
</script>
