var render = function render(){var _vm=this,_c=_vm._self._c;return _c('custom-drawer',{attrs:{"size":"1100px","wrapper_closable":false,"confirm_to_close":false,"visible":_vm.visible_in_line},on:{"update:visible":function($event){_vm.visible_in_line=$event}}},[_c('template',{slot:"title"},[_c('div',{staticClass:"flex items-center h-full pl-4 pr-8 space-x-4"},[_c('p',{staticClass:"font-semibold"},[_vm._v(" "+_vm._s(_vm.$t('page.campaign.add_products'))+" ")]),_c('div',{staticClass:"!ml-auto"}),_c('el-button',{attrs:{"icon":"el-icon-check","type":"primary","size":"small","disabled":_vm.multiple_selection.length === 0},on:{"click":_vm.handleChooseProducts}},[_vm._v(" "+_vm._s(_vm.$t('button.confirm'))+" ")])],1)]),_c('div',{staticClass:"px-4 py-8"},[_c('div',{staticClass:"component-box"},[_c('div',{staticClass:"flex items-center mb-2 space-x-2"},[_c('label-outside-input-select',{attrs:{"label":_vm.$t('page.campaign.spu_id_or_product_name')}},[_c('el-input',{staticClass:"w-[240px]",attrs:{"type":"text","size":"small","clearable":true,"placeholder":_vm.$t('input.placeholder.please_input')},on:{"clear":_vm.findSpuId},model:{value:(_vm.search_spu_id),callback:function ($$v) {_vm.search_spu_id=$$v},expression:"search_spu_id"}},[_c('el-button',{attrs:{"slot":"append","icon":"el-icon-search"},on:{"click":_vm.findSpuId},slot:"append"})],1)],1),_c('div',{staticClass:"!ml-auto"}),_c('el-button',{attrs:{"icon":"el-icon-refresh","size":"small"},on:{"click":function($event){return _vm.fetchProductCatalogs()}}}),_c('el-button',{attrs:{"type":_vm.multiple_selection.length === _vm.data_list.length &&
                        _vm.data_list.length > 1
                            ? 'danger'
                            : '',"size":"small"},on:{"click":_vm.handleSelectAll}},[_vm._v(" "+_vm._s(_vm.multiple_selection.length === _vm.data_list.length ? _vm.$t('common.deselect_all') : _vm.$t('button.select_all')))])],1),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.m__loading),expression:"m__loading"}],attrs:{"height":"640","data":_vm.data_list},on:{"sort-change":_vm.handleSortChange}},[_c('el-table-column',{attrs:{"width":"320","label":_vm.$t('common.name')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"flex space-x-2"},[_c('img',{staticClass:"w-[60px] h-[60px] object-contain",attrs:{"src":scope.row.product_image_url}}),_c('div',[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":scope.row.title,"placement":"top"}},[_c('p',{staticClass:"font-semibold"},[_vm._v(" "+_vm._s(scope.row.title)+" ")])]),_c('p',{staticClass:"text-desc-text"},[_vm._v(" SPU ID : "+_vm._s(scope.row.item_group_id)+" ")]),(
                                        scope.row.landing_page &&
                                        scope.row.landing_page.url
                                    )?_c('a',{staticClass:"text-desc-text underline",attrs:{"href":scope.row.landing_page.url,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('button.see_details'))+" ")]):_vm._e()],1)])]}}])}),_c('el-table-column',{attrs:{"width":"160","label":_vm.$t('common.price'),"prop":'min_price',"sortable":"custom"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                                scope.row.price &&
                                scope.row.price.sale_price
                            )?_c('p',[_vm._v(" Sale: "+_vm._s(_vm.p__renderMoneyByCurrency( scope.row.price.sale_price, scope.row.price.currency ))+" ")]):_c('p',[_vm._v(" Sale: "+_vm._s(_vm.p__renderMoneyByCurrency( scope.row.min_price, scope.row.currency ))+" ")]),(scope.row.price_list)?_c('p',{staticClass:"text-desc-text"},[_vm._v(" "+_vm._s(_vm.showPrice( scope.row.price_list, scope.row.price.currency ))+" ")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"min-width":"120","label":_vm.$t('common.description')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.description)?_c('p',{staticClass:"util-max-2-text-lines"},[_vm._v(" "+_vm._s(scope.row.description)+" ")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('common.category')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('p',[_vm._v(" "+_vm._s(scope.row.category)+" ")])]}}])}),_c('el-table-column',{attrs:{"width":"120","label":_vm.$t('page.campaign.historical_sales'),"prop":'historical_sales',"sortable":"custom"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('p',[_vm._v(" "+_vm._s(scope.row.historical_sales)+" ")])]}}])}),_c('el-table-column',{attrs:{"width":"120","label":_vm.$t('common.option')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                                _vm.multiple_selection.some(
                                    (item) =>
                                        item.item_group_id ===
                                        scope.row.item_group_id
                                )
                            )?_c('el-button',{attrs:{"size":"small","type":"danger"},on:{"click":function($event){return _vm.handleChooseProduct(scope.row)}}},[_vm._v(" "+_vm._s(_vm.$t('common.unchecked'))+" ")]):_c('el-button',{attrs:{"size":"small","disabled":scope.row.stocks === 0},on:{"click":function($event){return _vm.handleChooseProduct(scope.row)}}},[_vm._v(" "+_vm._s(_vm.$t('button.choose'))+" ")])]}}])})],1),_c('p',{staticClass:"mt-2 text-sm"},[_vm._v(" "+_vm._s(_vm.multiple_selection.length)+" / "+_vm._s(_vm.max_products)+" "+_vm._s(_vm.$t('common.selected'))+" ")])],1)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }