var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"component-box"},[_c('p',{staticClass:"mb-8 font-semibold"},[_vm._v(" "+_vm._s(_vm.$t('page.campaign.shop_settings'))+" ")]),_c('div',{staticClass:"flex flex-col space-y-6"},[_c('div',[_c('p',{staticClass:"component-label"},[_vm._v(" "+_vm._s(_vm.$t('common.ad_type'))+" ")]),_c('div',{staticClass:"flex space-x-4"},_vm._l((_vm.filter_ad_types),function(i,idx){return _c('div',{key:idx,staticClass:"w-[280px] rounded-lg p-4 flex space-x-4 cursor-pointer border-[1px] box-border",class:[
                        _vm.shopping_ads_type === i.value
                            ? 'border-brand-atosa bg-white'
                            : 'border-transparent bg-bg hover:bg-border'
                    ],on:{"click":function($event){return _vm.handleChangeShoppingAdsType(i.value)}}},[_c('div',[_c('p',{staticClass:"font-semibold text-sm mb-1"},[_vm._v(" "+_vm._s(i.title)+" ")]),_c('p',{staticClass:"component-text-desc min-h-[44px]"},[_vm._v(" "+_vm._s(i.description)+" ")])])])}),0),(_vm.shopping_ads_type === 'PRODUCT_SHOPPING_ADS')?_c('el-alert',{staticClass:"mt-4",attrs:{"title":_vm.$t('page.campaign.desc_product_shopping_ads_2'),"type":"warning"}}):_vm._e()],1),_c('div',{attrs:{"id":"input_adgroup_store_wrapper"}},[_c('p',{staticClass:"component-label"},[_vm._v(" "+_vm._s(_vm.$t('common.shop'))+" ")]),_c('el-select',{staticClass:"w-[320px]",attrs:{"id":"input_adgroup_store","disabled":_vm.type_form !== 'create',"size":"small","filterable":"","placeholder":_vm.$t('input.placeholder.please_select')},model:{value:(_vm.selected_item_in_line.store_id),callback:function ($$v) {_vm.$set(_vm.selected_item_in_line, "store_id", $$v)},expression:"selected_item_in_line.store_id"}},[_c('el-option-group',{attrs:{"label":_vm.$t('common.shop')}},_vm._l((_vm.shop_options),function(item){return _c('el-option',{key:item.store_id,attrs:{"value":item.store_id,"label":item.store_name}},[_c('div',{staticClass:"flex flex-col py-2"},[_c('span',{staticClass:"leading-3 text-xs font-semibold"},[_vm._v(" "+_vm._s(item.store_name)+" ")]),_c('span',{staticClass:"component-text-desc"},[_vm._v(" #"+_vm._s(item.store_id)+" ")])])])}),1)],1),(_vm.type_form === 'create')?_c('el-button',{staticClass:"ml-2",attrs:{"icon":"el-icon-refresh","size":"small"},on:{"click":_vm.fetchShop}}):_vm._e()],1),(
                (_vm.shopping_ads_type !== 'PRODUCT_SHOPPING_ADS' &&
                    _vm.type_form === 'create') ||
                _vm.shopping_ads_type === 'LIVE'
            )?_c('div',{attrs:{"id":"input_adgroup_identity_wrapper"}},[_c('p',{staticClass:"component-label"},[_vm._v(" "+_vm._s(_vm.$t('common.identity'))+" ")]),_c('p',{staticClass:"component-text-desc mb-4"},[_vm._v(" "+_vm._s(_vm.$t('page.campaign.desc_shop_settings_identity'))+" ")]),_c('div',{staticClass:"flex"},[_c('el-select',{staticClass:"w-[320px]",attrs:{"id":"input_adgroup_identity","value":_vm.selected_item_in_line.identity_id,"size":"small","disabled":_vm.type_form !== 'create',"filterable":"","placeholder":_vm.$t('input.placeholder.select_tiktok_account')},on:{"change":_vm.handleChangeIdentity}},[_c('el-option-group',{attrs:{"label":_vm.$t('common.tiktok_accounts')}},_vm._l((_vm.identity_options),function(item){return _c('el-option',{key:item.identity_id,attrs:{"value":item.identity_id,"label":item.display_name}},[_c('div',{staticClass:"flex items-center py-1 space-x-2"},[_c('el-avatar',{attrs:{"size":32}},[(item.profile_image_url)?_c('img',{attrs:{"src":item.profile_image_url}}):_c('span',[_vm._v(" "+_vm._s(item.display_name)+" ")])]),_c('div',{staticClass:"flex flex-col"},[_c('span',{staticClass:"leading-5 text-xs font-semibold"},[_vm._v(" "+_vm._s(item.display_name)+" ")]),(
                                            item.identity_authorized_bc_id
                                        )?_c('span',{staticClass:"component-text-desc"},[_vm._v(" #"+_vm._s(item.identity_authorized_bc_id)+" ")]):_vm._e()])],1)])}),1)],1),(_vm.type_form === 'create')?_c('el-button',{staticClass:"ml-2",attrs:{"icon":"el-icon-refresh","size":"small"},on:{"click":_vm.fetchIdentity}}):_vm._e()],1)]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }