import { getBusinessCenters } from '@/services/atosa-tiktok-ads/business-center'

const selectedBusinessCenterMixin = {
    computed: {
        m__selected_business_center() {
            return this.$store.getters.businessCenter
                .s__selected_business_center
        },

        m__business_centers() {
            return this.$store.getters.businessCenter.s__business_centers
        }
    },

    mounted() {
        this.m__fetchBusinessCenters()
    },

    methods: {
        m__handleChangeSelectedBC(value) {
            localStorage.setItem(
                `id_bc_${this.$store.getters.tiktokBusiness.s__selected_tiktok_business.id}`,
                value.bc_id
            )
            window.location = window.location.pathname
        },

        async m__handleChangeSelectedBCNotRefreshPage(val) {
            await this.$store.dispatch(
                'businessCenter/s__setSelectedBusinessCenter',
                val
            )
            localStorage.setItem(
                `id_bc_${this.$store.getters.tiktokBusiness.s__selected_tiktok_business.id}`,
                val.bc_id
            )
        },

        async m__fetchBusinessCenters() {
            if (
                !this.$store.getters.tiktokBusiness?.s__selected_tiktok_business
            ) {
                return
            }
            const tiktok_account_id =
                this.$store.getters.tiktokBusiness.s__selected_tiktok_business.id
            const response = await getBusinessCenters(tiktok_account_id)

            if (response.code === 0 && response.data.length > 0) {
                await this.$store.dispatch(
                    'businessCenter/s__setBusinessCenters',
                    response.data
                )

                this.m__setSelectedBusinessCenter(
                    tiktok_account_id,
                    response.data
                )
            } else {
                await this.$store.dispatch(
                    'businessCenter/s__setBusinessCenters',
                    []
                )
            }
        },

        async m__setSelectedBusinessCenter(
            tiktok_account_id,
            business_centers
        ) {
            if (business_centers.length === 0) {
                return
            }

            const id_bc = localStorage.getItem(`id_bc_${tiktok_account_id}`)

            if (!id_bc) {
                const bc = business_centers[0]
                localStorage.setItem(`id_bc_${tiktok_account_id}`, bc.bc_id)
                await this.$store.dispatch(
                    'businessCenter/s__setSelectedBusinessCenter',
                    bc
                )
                return
            }

            const temp_bc = business_centers.find(
                (item) => item.bc_id === id_bc
            )
            if (!temp_bc) {
                const bc = business_centers[0]
                localStorage.setItem(`id_bc_${tiktok_account_id}`, bc.bc_id)
                await this.$store.dispatch(
                    'businessCenter/s__setSelectedBusinessCenter',
                    bc
                )
                return
            }

            await this.$store.dispatch(
                'businessCenter/s__setSelectedBusinessCenter',
                temp_bc
            )
        }
    }
}

export default selectedBusinessCenterMixin
