const lang = {
    atosa_core: {
        description_form:
            'Nếu bạn có yêu cầu cho ATOSA, vui lòng truy cập biểu mẫu này hoặc liên hệ trực tiếp với ATOSA',
        find_out_more: 'Tìm hiểu thêm về ATOSA',
        message: {
            email_has_registered:
                'Địa chỉ email đã được sử dụng để đăng ký trước đó, vui lòng kiểm tra và thử lại sau',
            password_wrong:
                'Tài khoản hoặc mật khẩu không đúng, vui lòng kiểm tra và thử lại sau',
            valid_email:
                'Định dạng email không hợp lệ, vui lòng kiểm tra va thử lại sau',
            valid_phone:
                'Định dạng số điện thoại không hợp lệ, vui lòng kiểm tra và thử lại sau'
        }
    },
    button: {
        default_column: 'Cột mặc định',
        column_display: 'Hiển thị cột',
        back: 'Quay lại',
        create_campaign: 'Tạo chiến dịch',
        choose: 'Chọn',
        im_interested: 'Tôi quan tâm',
        apply: 'Áp dụng',
        back_dashboard: 'Trở về trang chủ',
        buy_package: 'Mua gói',
        choose_plan: 'Chọn gói',
        clear_all: 'Xóa hết',
        close: 'Đóng',
        confirm: 'Xác nhận',
        connect: 'Liên kết',
        continue: 'Tiếp tục',
        create: 'Tạo mới',
        create_again: 'Tạo lại',
        custom_column: 'Tùy chỉnh cột',
        delete: 'Xoá',
        edit: 'Chỉnh sửa',
        filters: 'Lọc',
        log_out: 'Đăng xuất',
        next: 'Tiếp tục',
        reset: 'Cài lại',
        return: 'Trở lại',
        sample_target_list: 'Danh sách mục tiêu mẫu',
        save_custom_target: 'Lưu mẫu',
        save_custom_target_name: 'Lưu mẫu tên',
        save_target: 'Lưu mục tiêu',
        see_details: 'Xem chi tiết',
        see_more: 'Xem thêm',
        select_all: 'Chọn tất cả',
        select_custom_target: 'Chọn mẫu',
        send_request: 'Gửi yêu cầu',
        skip: 'Bỏ qua',
        view_data: 'Xem số liệu'
    },
    common: {
        number_zalo_to_noti: 'Số Zalo để nhận thông báo',
        noti_channel: 'Kênh thông báo',
        data_field: 'Trường dữ liệu',
        google_drive_path: 'Đường dẫn Google drive',
        file_name_google_sheet: 'Tên file Google sheet',
        create_configuration: 'Tạo cấu hình',
        google_account_verification: 'Xác minh tài khoản Google',
        cpa_suggestion: 'Gợi ý CPA',
        integration_google: 'Tích hợp Google sheet',
        desc_integration_google:
            'Tính năng sẽ giúp quý khách xuất dữ liệu tài khoản quảng cáo qua Google Sheet hằng ngày',
        click_here: 'Bấm vào đây',
        affiliate: 'Tiếp thị liên kết',
        title: 'Tiêu đề',
        update: 'Cập nhật',
        save_item_success: 'Lưu dữ liệu thành công',
        this_month_last_year: 'Tháng này năm ngoái',
        last_month_last_year: 'Tháng trước năm ngoái',
        _1st_quarter: 'Quý I',
        _2nd_quarter: 'Quý II',
        _3rd_quarter: 'Quý III',
        _4th_quarter: 'Quý IV',
        _1st_quarter_last_year: 'Quý I năm ngoái',
        _2nd_quarter_last_year: 'Quý II năm ngoái',
        _3rd_quarter_last_year: 'Quý III năm ngoái',
        _4th_quarter_last_year: 'Quý IV năm ngoái',
        other: 'Khác',
        app: 'Ứng dụng',
        level_xxx: 'Bậc {xxx}',
        add_screen_to_compare_audience:
            'Thêm màn hình hiển biểu đồ thông số đối tượng , giúp so sánh dễ hơn',
        add_screen: 'Thêm màn hình',
        first_name: 'Tên đệm',
        last_name: 'Tên',
        number: 'Số',
        expire_month: 'Tháng hết hạn',
        expire_year: 'Năm hết hạn',
        credit_card: 'Thẻ tín dụng',
        shop_ads: 'Quảng cáo mua sắm',
        postal_code: 'Mã bưu điện',
        email_billing: 'Email để nhận hóa đơn',
        city: 'Thành phố',
        phone_number: 'Số điện thoại',
        address: 'Địa chỉ',
        recharge: 'Nạp tiền',
        billing_address: 'Địa chỉ hóa đơn',
        upload_file: 'Tải lên',
        name_template: 'Mẫu tên',
        start_time: 'Thời gian bắt đầu',
        end_time: 'Thời gian kết thúc',
        billing: 'Hóa đơn',
        week: 'Tuần',
        adjust: 'Điều chỉnh',
        day_monday: 'T2',
        day_tuesday: 'T3',
        day_wednesday: 'T4',
        day_thursday: 'T5',
        day_friday: 'T6',
        day_saturday: 'T7',
        day_sunday: 'CN',
        see_more: 'Xem thêm',
        deselect_all: 'Bỏ chọn tất cả',
        unchecked: 'Bỏ chọn',
        form: 'Biểu mẫu',
        default: 'Mặc định',
        delete_all: 'Xóa tất cả',
        delete: 'Xóa',
        stock: 'Còn hàng',
        category: 'Danh mục',
        tool: 'công cụ',
        ad_cannot_previewed_yet: 'Quảng cáo này chưa thể xem trước',
        preview: 'Xem trước',
        overview: 'Tổng quan',
        total_balance: 'Tổng số dư',
        manage_ad_group_budgets: 'Quản lý ngân sách nhóm quảng cáo',
        budget_management: 'Quản lý ngân sách',
        new_name: 'Tên mới',
        old_name: 'Tên cũ',
        batch_name_adjustment: 'Chỉnh tên hàng loạt',
        desc_create_campaign_by_atosa:
            'Quý khách nên dùng tài khoản quảng cáo tạo từ ATAME để thiết lập chiến dịch mới.Điều này giúp ATAME có thêm thông tin, tính năng giúp chiến dịch tối ưu',
        desc_tiktok_business:
            'Tài khoản Tiktok doanh nghiệp là tài khoản quý khách đăng nhập Tiktok Ads. ATAME cần liên kết với tài khoản Tiktok doanh nghiệp để lấy thông tin, số liệu, giúp quý khách chạy quảng cáo dễ dàng',
        minus: 'Trừ đi',
        desc_permission:
            'Sử dụng tài khoản phụ giúp việc quản lý, chạy quảng cáo TiKTok an toàn hơn, dễ quản lý hơn',
        system: 'Hệ thống',
        replace: 'Thay thế',
        plus: 'Cộng thêm',
        Minus: 'Trừ đi',
        batch_editing: 'Chỉnh hàng loạt',
        no_suitable_ad_groups_bulk_budget_changes:
            'Không có nhóm quảng cáo phù hợp để thay đổi ngân sách hàng loạt',
        batch_budget_adjustments: 'Chỉnh ngân sách hàng loạt',
        percent: 'Phần trăm',
        new_budget: 'Ngân sách mới',
        detailed_analysis_none: 'Phân tích chi tiết: Không',
        detailed_analysis_day: 'Phân tích chi tiết: Ngày',
        detailed_analysis_week: 'Phân tích chi tiết: Tuần',
        detailed_analysis_month: 'Phân tích chi tiết: Tháng',
        load_more: 'Tải thêm',
        stop_after: 'Dừng sau',
        never_stop: 'Không bao giờ dừng',
        config: 'Cấu hình',
        config_auto_refresh: 'Cấu hình tự động cập nhật',
        refresh_second: 'Cập nhật sau mỗi',
        edit_budget: 'Thay đổi ngân sách',
        unknown: 'Chưa xác định',
        frozen: 'Đóng băng',
        havent_package: 'Quý khách chưa có gói',
        just_register_package: 'Hãy đăng ký gói tại đây',
        change_password_success: 'Đổi mật khẩu thành công',
        old_password: 'Mật khẩu cũ',
        new_password: 'Mật khẩu mới',
        change_password: 'Đổi mật khẩu',
        feature_must_buy:
            'Quý khách phải mua gói trả phí để sử dụng tính năng này',
        apply_package: 'Áp dụng gói',
        message_apply_package_success: 'Áp dụng gói thành công',
        quantity: 'Số lượng',
        popular: 'Phổ biến',
        feature: 'Tính năng',
        free: 'Miễn phí',
        add_to_target: 'Thêm những đối tượng trong tệp vào danh sách',
        remove_to_target: 'Loại bỏ những đối tượng trong tệp ra khỏi danh sách',
        replate_to_target: 'Thay thế những đối tượng trong tệp vào danh sách',
        delegate_permission_success: 'Phân quyền cho tài khoản thành công',
        delegate_permission_error: 'Phân quyền cho tài khoản thất bại',
        report_ads: 'Thống kê QC',
        manager_ads: 'Quản lý QC',
        auto_ads: 'QC Tự động',
        delegate_permissions_for_second_account: 'Phân quyền cho tài khoản phụ',
        confirm_password: 'Nhập lại mật khẩu',
        password: 'Mật khẩu',
        username: 'Tên đăng nhập',
        edit_second_account: 'Chỉnh sửa tài khoản phụ',
        create_second_account: 'Tạo tài khoản phụ',
        second_account: 'Tài khoản phụ',
        last_access_time: 'Thời gian cuối truy cập',
        permission: 'Phân quyền',
        transaction_details: 'Chi tiết giao dịch',
        extra: 'Kèm thêm',
        save: 'Lưu',
        close_list: 'Đóng danh sách',
        list: 'Danh sách',
        description_introduce_atodake:
            "* Hãy nhấp nút 'Tôi quan tâm' để biết thêm chi tiết về sản phẩm này",
        introduce_atosaca:
            'ATOSACA - Phần mềm quản lý chat đa kênh hỗ trợ nhà bán hàng quản lý tin nhắn Shopee, Tiktok. Tích hợp với phần mềm quản lý bán hàng đa kênh',
        introduce_atodake:
            'Quý khách đang gặp khó khăn khi vận đơn giữa các sàn như Tiktok , Shopee , ... Atosa đã ra mắt sản phẩm ATODAKE để khắc phục , tối ưu vấn đề này',
        end_datetime: 'Thời gian kết thúc',
        access_settings: 'Cài đặt truy cập',
        action: 'Hành động',
        active: 'Hoạt động',
        ad: 'Quảng cáo',
        ad_account: 'Tài khoản QC',
        ad_account_balance: 'Số dư tài khoản quảng cáo',
        ad_account_id: 'ID Tài khoản QC',
        ad_account_name: 'Tên Tài khoản QC',
        ad_scheduling: 'Kế hoạch quảng cáo',
        ad_type: 'Loại quảng cáo',
        adgroup: 'Nhóm quảng cáo',
        adjust_to: 'Điều chỉnh tới',
        admin: 'Quản trị viên',
        ads_account: 'Tài khoản QC',
        ads_manager: 'Quản lý quảng cáo',
        advanced_settings: 'Cài đặt nâng cao',
        advertiser_account: 'Tài khoản quảng cáo',
        advertiser_accounts: 'Tài khoản Quảng cáo',
        advertising_fee: 'Phí quảng cáo',
        advertising_objective: 'Mục tiêu quảng cáo',
        age: 'Tuổi',
        age_and_gender: 'Tuổi và giới tính',
        all: 'Tất cả',
        all_active_ad: 'Tất cả quảng cáo hoạt động',
        all_active_adgroup: 'Tất cả nhóm quảng cáo hoạt động',
        all_active_campaign: 'Tất cả chiến dịch hoạt động',
        all_inactive_ad: 'Tất cả quảng cáo không hoạt động',
        all_inactive_adgroup: 'Tất cả nhóm quảng cáo không hoạt động',
        all_inactive_campaign: 'Tất cả chiến dịch không hoạt động',
        an_error_has_occurred: 'Đã có lỗi xảy ra',
        analyst: 'Nhà phân tích',
        and: 'Và',
        asset: 'Tài nguyên',
        at_least: 'Ít nhất',
        audience: 'Đối tượng',
        automatic_rule: 'Quy tắc tự động',
        balance: 'Số dư',
        balance_type: 'Loại giao dịch',
        basic: 'Căn bản',
        between: 'Trong khoảng',
        budget: 'Ngân sách',
        budget_mode_infinite: 'Không giới hạn',
        business_center: 'Trung tâm doanh nghiệp',
        business_centers: 'Trung tâm doanh nghiệp',
        call_to_action: 'Kêu gọi hành động',
        campaign: 'Chiến dịch',
        campaigns: 'Chiến dịch',
        cash_amount: 'Số tiền',
        catalog: 'Cửa hàng',
        change: 'Thay đổi',
        click: 'Lượt nhấp',
        closed: 'Đã đóng',
        company: 'Công ty',
        compare: 'So sánh',
        completed: 'Đã hoàn thành',
        condition: 'Điều kiện',
        confirm_linked: 'Xác nhận đã liên kết',
        connect_tiktok_account: 'Kết nối tài khoản Tiktok doanh nghiệp',
        contains: 'Chứa',
        content: 'Nội dung',
        conversion: 'Lượt chuyển đổi',
        copied: 'Đã sao chép',
        copy: 'Sao chép',
        copy_ad: 'Sao chép quảng cáo',
        copy_adgroup: 'Sao chép nhóm quảng cáo',
        cost: 'Chi phí',
        country: 'Quốc gia',
        create_data_success: 'Tạo dữ liệu thành công',
        created_at: 'Ngày tạo',
        created_by: 'Người tạo',
        created_datetime: 'Ngày tạo',
        currency: 'Tiền tệ',
        current_ad_account_balance: 'Số dư Tài khoản QC',
        daily: 'Hằng ngày',
        daily_budget: 'Ngân sách hằng ngày',
        dashboard: 'Báo cáo tổng thể',
        date: 'Ngày',
        date_range: 'Khoảng thời gian',
        day: 'Ngày',
        days: 'Ngày',
        days_since_creation: 'Số ngày từ ngày tạo',
        decrease: 'Giảm',
        delete_data_confirm: 'Quý khách có muốn xóa dữ liệu này không?',
        delete_data_success: 'Xóa dữ liệu thành công',
        delete_success: 'Xóa thành công',
        deleted: 'Đã xóa',
        description: 'Mô tả',
        detailed_analysis: 'Phân tích chi tiết',
        device: 'Thiết bị',
        disable: 'Không hoạt động',
        dynamic_daily_budget: 'Ngân sách hằng ngày linh hoạt',
        edit: 'Chỉnh sửa',
        edit_name: 'Chỉnh sửa tên',
        email_address: 'Địa chỉ email',
        enable: 'Hoạt động',
        end_with: 'Kết thúc bằng',
        equal: 'Bằng',
        error: 'Lỗi',
        error_message: 'Thông báo lỗi',
        exclude: 'Loại trừ',
        expire: 'Đã hết hạn',
        export_excel: 'Xuất file Excel',
        female: 'Nữ',
        finance: 'Tài chính',
        format_date: 'DD/MM/YYYY',
        format_fulldate: 'HH:mm DD/MM/YYYY',
        format_value_date_picker: 'dd-MM-yyyy',
        format_value_date_picker_moment: 'DD-MM-YYYY',
        format_value_date_picker_with_time: 'HH:mm dd-MM-yyyy',
        friday: 'Thứ sáu',
        gender: 'Giới tính',
        general: 'Quản lý',
        greater_than: 'Lớn hơn',
        havent_connect_tiktok_shop:
            'Quý khách chưa kết nối với Tiktok shop . Nếu muốn chạy quảng cáo LIVE , quý khách hãy kết nối với Tiktok shop của mình để chúng tôi có thể hỗ trợ tốt nhất',
        hour: 'Giờ',
        i_got_it: 'Tôi đã hiểu',
        identity: 'Danh tính',
        if: 'Nếu',
        impression: 'Lượt hiển thị',
        in_review: 'Đang xét duyệt',
        inactive: 'Không hoạt động',
        include: 'Bao gồm',
        increase: 'Tăng',
        info: 'Thông tin',
        invalid_period: 'Vô thời hạn',
        language: 'Ngôn ngữ',
        last_month: 'Tháng trước',
        learn_more: 'Xem thêm',
        less_than: 'Bé hơn',
        lifetime: 'Trọn đời',
        lifetime_budget: 'Ngân sách trọn đời',
        location: 'Vị trí',
        male: 'Nam',
        manage: 'Quản lý',
        management_accounts: 'Tiktok doanh nghiệp quản lý',
        member: 'Thành viên',
        members: 'Thành viên',
        minute: 'Phút',
        monday: 'Thứ hai',
        month: 'Tháng',
        name: 'Tên',
        network: 'Mạng',
        never_show_popup: 'Không hiện popup này nữa',
        no_data: 'Không có dữ liệu',
        not_contains: 'Không chứa',
        not_delivering: 'Không phân phối',
        note: 'Ghi chú',
        object: 'Đối tượng',
        objective: 'Mục tiêu',
        objective_type: 'Mục tiêu quảng cáo',
        operating_system: 'Hệ điều hành',
        operator: 'Người vận hành',
        option: 'Tùy chọn',
        optional: 'Tùy chọn',
        package: 'Gói sản phẩm',
        page: 'Trang',
        partners: 'Đối tác',
        past_xxx_days: '{xxx} ngày qua',
        payment: 'Thanh toán',
        performance: 'Hiệu suất',
        price: 'Giá',
        product: 'Sản phẩm',
        products: 'Sản phẩm',
        province: 'Phân vùng',
        rank: 'Thứ hạng',
        reach: 'Tiếp cận',
        real_time: 'Thời gian thực',
        registered_area: 'Khu vực đăng ký',
        request_sent: 'Yêu cầu đã được gửi',
        request_sent_success: 'Gửi yêu cầu thành công',
        result: 'Kết quả',
        role: 'Quyền',
        rule: 'Quy tắc',
        sample: 'Mẫu',
        saturday: 'Thứ bảy',
        schedule: 'Lịch trình',
        second: 'Giây',
        selected: 'Được chọn',
        setting: 'Cài đặt',
        setup_auto: 'Cài đặt tự động',
        shop: 'Cửa hàng',
        shops: 'Cửa hàng',
        show: 'Xem',
        standard: 'Tiêu chuẩn',
        start_with: 'Bắt đầu bằng',
        status: 'Trạng thái',
        string_equal: 'Là',
        success: 'Thành công',
        sunday: 'Chủ nhật',
        suspended: 'Bị cấm',
        tax: 'Thuế',
        text: 'Nội dung',
        then: 'Thì',
        this_month: 'Tháng này',
        this_week: 'Tuần này',
        last_week: 'Tuần trước',
        thursday: 'Thứ năm',
        tiktok_account: 'Tài khoản Tiktok',
        tiktok_accounts: 'Tài khoản Tiktok',
        tiktok_identity: 'Tiktok danh tính',
        tiktok_business: 'Tiktok doanh nghiệp',
        tiktok_shops: 'Tiktok Shop',
        time: 'Thời gian',
        timezone: 'Múi giờ',
        today: 'Hôm nay',
        total: 'Tổng',
        total_amount: 'Tổng số tiền',
        tracking: 'Theo dõi',
        traffic: 'Tương tác',
        transaction_time: 'Thời gian giao dịch',
        transaction_type: 'Loại giao dịch',
        transfers: 'Nạp tiền TKQC',
        tuesday: 'Thứ ba',
        turn: 'Lượt',
        turn_off: 'Tắt',
        turn_on: 'Bật',
        update_data_confirm: 'Quý khách có muốn cập nhật dữ liệu này không?',
        update_data_success: 'Cập nhật dữ liệu thành công',
        using: 'Đang sử dụng',
        value: 'Giá trị',
        video_play: 'Xem video',
        view: 'Lượt xem',
        warning: 'Cảnh báo',
        website: 'Website',
        wednesday: 'Thứ tư',
        xxx_has_plural_noun: '{xxx}',
        xxx_id: 'ID {xxx}',
        xxx_status: 'Trạng thái {xxx}',
        year: 'năm',
        yesterday: 'Hôm qua',
        you_can_create_up_to_bc: 'Quý khách có thể tạo tối đa {number} BC',
        your_account_balance: 'Số dư Tài khoản Atosa'
    },
    date_picker: {
        options: {
            last_xxx_days: '{xxx} ngày trước'
        },
        placeholder: {
            end: 'Đến ngày',
            start: 'Từ ngày'
        }
    },
    error: {
        cannot_access: 'Không thể truy cập App'
    },
    input: {
        placeholder: {
            amount_must_at_least: 'Số tiền phải ít nhất là {value}',
            custom_must_at_least: '{name} phải ít nhất là {value}',
            please_input: 'Hãy nhập thông tin',
            please_select: 'Hãy chọn thông tin',
            search_by_name_or_id: 'Tìm kiếm bằng tên hoặc ID',
            select_ads_account: 'Chọn tài khoản quảng cáo',
            select_all: 'Mặc định chọn tất cả',
            select_business_center: 'Chọn trung tâm doanh nghiệp',
            select_tiktok_account: 'Chọn tài khoản tiktok',
            value_must_at_least: 'Giá trị phải ít nhất là {value}',
            value_must_gte: 'Giá trị phải lớn hơn hoặc bằng {value}'
        },
        rule: {
            not_empty: 'Không được để trống'
        }
    },
    message: {
        close_wont_be_save: 'Quá trình của quý khách sẽ không được lưu',
        expired_package:
            'Gói <span class="font-semibold">{name}</span> của quý khách đã hết hạn từ ngày <span class="font-semibold">{license_to}</span>. Vui lòng gia hạn gói sản phẩm để sử dụng.',
        xxx_fail: '{xxx} thất bại',
        xxx_success: '{xxx} thành công'
    },
    page: {
        billing: {
            desc_billing_address:
                'Đây là những địa chỉ mà bạn có thể sử dụng để chúng tôi gửi hóa đơn'
        },
        dashboard: {
            overview_ads_xxx: 'Tổng quan về dữ liệu quảng cáo của <b>{xxx}</b>',
            spend_overview_lifetime: 'Chi phí trọn đời',
            impressions_overview_lifetime: 'Lượt hiển thị trọn đời',
            conversion_overview_lifetime: 'Lượt chuyển đổi trọn đời',
            spend_overview_today: 'Chi phí hôm nay',
            impressions_overview_today: 'Lượt hiển thị hôm nay',
            conversion_overview_today: 'Lượt chuyển đổi hôm nay'
        },
        admin: {
            cash_amount: 'Số tiền nạp',
            fee_amount: 'Thuế nhà thầu',
            payment_history: 'Lịch sử thanh toán',
            recharge_history: 'Lịch sử nạp tiền',
            total_cash_amount: 'Tổng thanh toán'
        },
        authen_tiktok_account: {
            desc: 'Xin vui lòng đợi ít phút . Nếu việc xác minh quá lâu , quý khách có thể làm mới lại website'
        },
        campaign: {
            ad_entity_type: 'Mô hình quảng cáo phổ biến',
            in_xxx_campaigns: 'Trong {xxx} chiến dịch',
            in_xxx_adgroups: 'Trong {xxx} nhóm quảng cáo',
            choose_product_from: 'Chọn sản phẩm từ',
            contact_atosa:
                'Nếu quý khách có nhu cầu chạy quảng cáo Tiktok hoặc muốn tư vấn để lên những chiến dịch hiệu quả, hãy liên hệ với ATOSA',
            historical_sales: 'Số lượng bán',
            view_mode: 'Chế độ xem',
            campaign_status_tiktok_post_hesitate_recommend: 'Công khai',
            campaign_status_tiktok_post_only_friend_see: 'Bạn bè',
            campaign_status_tiktok_post_only_author_see: 'Ẩn',
            bid_strategy_minimum_roas: 'ROAS tối thiểu',
            bid_strategy_gmv: 'Doanh thu gộp cao nhất',
            bid_strategy_highest_value: 'Giá trị cao nhất',
            bid_strategy_max_conversion: 'Phân phối tối đa',
            instant_form: 'Biểu mẫu mở tức thì',
            dpa_target_audience_type_prospecting:
                'Tìm kiếm khách hàng tiềm năng',
            dpa_target_audience_type_retargeting: 'Nhắm lại đối tượng mục tiêu',
            get_target: 'Nhắm mục tiêu',
            dpa_target_audience_type: 'Đối tượng danh mục bán hàng',
            adjust_batch_names_according_to_name_template:
                'Điều chỉnh tên hàng loạt tên theo mẫu tên',
            upload_video_success: 'Tải video thành công',
            desc_upload_video_1:
                '<b>Định dạng được đề xuất</b>: .mp4, .mov, .mpeg hoặc .avi',
            desc_upload_video_2: '<b>Dung lượng tệp</b>: 500 MB',
            desc_upload_video_3:
                '<b>Yêu cầu về tỷ lệ khung hình co cho tất cả các vị trí</b>: Ngang(16:9) / Vuông(1:1) / Dọc(9:16)',
            desc_upload_video_4:
                '<b>Đối với TikTok</b>: Độ phân giải Ngang(960*540) / Vuông(640*640) / Dọc(540*960) / Tốc độ bit ≥ 516.00 Kbpskbps / Thời lượng 5-60s / Vùng an toàn Từ bên trái: 44px, Từ bên phải: 140px, Từ bên trên: 130px, Từ dưới: 483-484px',
            desc_upload_video_5:
                'Xem thông số kỹ thuật khuyên dùng đối với video',
            cannot_use_file: 'Tệp tin không thể sử dụng',
            edit_name_template_successfully: 'Chỉnh sửa mẫu tên thành công',
            add_field: 'Thêm trường',
            add_fields_to_form: 'Thêm trường để tạo mẫu',
            field_separator: 'Yếu tố phân tách trường',
            created_name_template_successfully: 'Tạo mẫu tên thành công',
            process_take_a_few_minutes:
                'Quá trình này sẽ mất vài phút. Quý khách vui lòng chờ đợi và giữ nguyên  màn hình để quá trình có thể hoàn thành',
            adjust_end_time_to_xxx_from_start_time:
                'Điều chỉnh thời gian kết thúc cách {xxx} so với thời gian bắt đầu',
            spu_id_or_product_name: 'SPU ID hoặc tên sản phẩm',
            ad_delivery_schedule: 'Lịch phân phối quảng cáo',
            distributed_all_day: 'Phân phối cả ngày',
            ads_will_be_delivered_all_day_long:
                'Quảng cáo sẽ được phân phối cả ngày',
            ad_delivery_time_frame:
                'Quảng cáo sẽ được phân phối vào những khung giờ sau',
            all_products: 'Tất cả sản phẩm',
            desc_all_products:
                'Cho mọi người thấy những sản phẩm mà họ có nhiều khả năng mua nhất từ ​​những sản phẩm có sẵn tại cửa hàng của bạn',
            specific_products: 'Sản phẩm cụ thể',
            desc_specific_products:
                'Hiển thị cho mọi người những sản phẩm mà họ có nhiều khả năng mua nhất từ ​​một hoặc nhiều sản phẩm bạn chọn từ cửa hàng của mình',
            add_plan: 'Thêm kế hoạch',
            budget_adjustment_plan: 'Kế hoạch điều chỉnh ngân sách',
            desc_budget_adjustment_plan:
                'ATAME sẽ điều chỉnh ngân sách theo những mốc thời gian mà quý khách cài đặt, giúp quý khách tự động hóa, tối ưu quảng cáo',
            total_daily_budget: 'Tổng ngân sách hằng ngày',
            total_lifetime_budget: 'Tổng ngân sách trọn đời',
            desc_product:
                'Chỉ những sản phẩm có sẵn mới có thể được chọn để phân phối quảng cáo. Điều này bao gồm các sản phẩm được đánh giá có đủ hàng tồn kho',
            product_shopping_ads: 'Quảng cáo mua sắm sản phẩm',
            desc_product_shopping_ads:
                'Sử dụng hình ảnh sản phẩm để quảng bá sản phẩm trong Trung tâm mua sắm',
            desc_product_shopping_ads_2:
                'Chỉ cung cấp ở Indonesia, Malaysia, Thái Lan, Philippines và Việt Nam',
            message_dont_choose_interest_keyword_ids:
                'Chưa chọn đối tượng để lưu vào tệp',
            desc_warning_add_balance:
                'Quý khách cần nạp thêm tiền để chiến dịch có thể hoạt động tốt nhất , tránh trường hợp các chiến dịch bị tắt khi vượt quá ngân sách',
            desc_optimization_goal_click:
                'Tối đa hóa số lượt nhấp vào sản phẩm của quý khách trong phiên LIVE.',
            desc_optimization_goal_viewer_retention:
                'Tối đa hóa số lần người xem xem LIVE của quý khách trong hơn 10 giây.',
            desc_optimization_goal_product_click_in_live:
                'Tối đa hóa số lượt nhấp vào sản phẩm của quý khách trong phiên LIVE.',
            desc_optimization_goal_initiate_checkout:
                'Tối đa hóa số đơn hàng gửi đi để đạt mức chi phí thấp nhất trên mỗi đơn hàng.',
            desc_optimization_goal_complete_payment:
                'Tối đa hóa số lượt nhấp "Mua ngay" và "Mua bằng phiếu giảm giá" trên sản phẩm của quý khách, hoặc hành động "Thanh toán".',
            optimization_goal_gmv: 'Doanh thu gộp',
            desc_optimization_goal_gmv:
                'Tối đa hóa tổng doanh thu của quý khách để đạt được lợi tức chi tiêu quảng cáo cao nhất có thể.',
            edit_rule: 'Chỉnh sửa quy tắc',
            message_start_time_less_than_or_equal_end_time_in_specified_time_period_automated_rule:
                'Thời gian bắt đầu phải bé hơn hoặc bằng thời gian kết thúc trong khoảng thời gian cụ thể',
            message_havent_select_day_in_specified_time_period_automated_rule:
                'Chưa chọn ngày trong khoảng thời gian cụ thể',
            message_overlap_specified_time_period_automated_rule:
                'Những khoảng thời gian cụ thể bị chồng lấn nhau',
            set_end_time: 'Thay đổi thời gian kết thúc của nhóm quảng cáo',
            pacing_smooth_to_fast: 'Chuyển phân phối tiêu chuẩn lên tăng tốc',
            pacing_fast_to_smooth: 'Chuyển phân phối tăng tốc về tiêu chuẩn',
            automated_rule_create_custom_rule: 'Tạo quy tắc tùy chỉnh',
            automated_rule_description_create_custom_rule: 'Không sử dụng mẫu',
            automated_rule_schedule_delivery: 'Phân phối theo lịch trình',
            automated_rule_description_schedule_delivery:
                'Bật/tắt quảng cáo vào thời gian cụ thể',
            automated_rule_control_budget: 'Kiểm soát ngân sách',
            automated_rule_description_control_budget:
                'Bật/tắt quảng cáo có ngân sách không mong đợi',
            description_column_reach:
                'Số người tối đa đã tiếp cận quảng cáo của quý khách.',
            description_column_result_rate:
                'Tỷ lệ kết quả quý khách nhận được trên tổng số lượt xem/nhấp chuột vào quảng cáo của quý khách. Vì một chiến dịch có thể có nhiều mục tiêu tối ưu hóa khác nhau, nên số liệu thống kê này chưa được hỗ trợ đối với các chiến dịch.<br/>Vui lòng vào mục quảng cáo hoặc nhóm quảng cáo để xem tỷ lệ kết quả. Tổng số được xác định dựa trên thời gian phát sinh lượt hiển thị quảng cáo.',
            description_column_cost_per_result:
                'Chi phí trung bình cho mỗi kết quả từ quảng cáo của quý khách. Vì một chiến dịch có thể có nhiều mục tiêu tối ưu hóa khác nhau, nên số liệu thống kê này chưa được hỗ trợ đối với các chiến dịch. Vui lòng vào mục quảng cáo hoặc nhóm quảng cáo để xem chi phí cho mỗi kết quả.<br/>Tổng số được xác định dựa trên thời gian phát sinh lượt hiển thị quảng cáo.',
            description_column_result:
                'Số lần quảng cáo của quý khách đạt được kết quả, dựa trên mục tiêu tối ưu hóa quý khách đã lựa chọn. Vì một chiến dịch có thể có nhiều mục tiêu tối ưu hóa khác nhau, nên số liệu thống kê này chưa được hỗ trợ đối với các chiến dịch. Vui lòng vào mục quảng cáo hoặc nhóm quảng cáo để xem kết quả.<br/>Tổng số được xác định dựa trên thời gian phát sinh lượt hiển thị quảng cáo.',
            description_column_spend:
                'Tổng số tiền ước tính quý khách đã chi cho chiến dịch, nhóm quảng cáo hoặc quảng cáo của quý khách trong suốt lịch trình',
            description_column_cpc:
                'Số tiền trung bình quý khách đã chi cho mỗi lượt nhấp chuột',
            description_column_impressions:
                'Số lần quảng cáo của quý khách xuất hiện trên màn hình',
            description_column_cpm:
                'Số tiền trung bình quý khách đã chi cho mỗi 1000 lượt hiển thị quảng cáo',
            description_column_cost_per_conversion:
                'Số tiền trung bình quý khách đã chi cho một lượt chuyển đổi.<br/>Tổng số được xác định dựa trên thời gian phát sinh lượt hiển thị quảng cáo',
            description_column_clicks:
                'Số lượt nhấp chuột vào quảng cáo của quý khách',
            description_column_conversion_rate:
                'Tỷ lệ kết quả quý khách nhận được trên tổng số lượt nhấp chuột vào quảng cáo của quý khách. <br/>Tổng số được xác định dựa trên thời gian phát sinh lượt hiển thị quảng cáo',
            description_column_ctr:
                'Tỷ lệ số lần quảng cáo của quý khách có người xem và nhấp chuột.',
            description_column_conversion:
                'Số lần quảng cáo của quý khách đạt được kết quả dựa trên mục tiêu và cài đặt quý khách đã chọn.<br/>Tổng số được xác định dựa trên thời gian phát sinh lượt hiển thị quảng cáo',
            accelerated: 'Tăng tốc',
            action_auto: 'Tự động',
            action_bid: 'Điều chỉnh giá thầu',
            action_frequency_cap: 'Giới hạn tần suất hành động',
            action_pause: 'Dừng',
            ad_creative: 'Quảng cáo sáng tạo',
            ad_details: 'Chi tiết quảng cáo',
            ad_format: 'Kiểu quảng cáo',
            ad_list: 'Danh sách QC',
            ad_setup: 'Ad setup',
            ad_status_adgroup_is_closed: 'Nhóm quảng cáo đã đóng',
            ad_status_music_copyright_missing: 'Thiếu bản quyền nhạc',
            ad_status_processing_audio_reupload_if_it_gets_stuck:
                'Đang xử lý âm thanh, re-up nếu bị dính',
            ad_status_replace_videos_with_errors_in_the_adgroup:
                'Thay thế video có lỗi trong nhóm quảng cáo',
            add_condition: 'Thêm điều kiện',
            add_action: 'Thêm hành động',
            add_config: 'Thêm cấu hình',
            add_products: 'Thêm sản phẩm',
            add_time: 'Thêm thời gian',
            adgroup_list: 'Danh sách nhóm quảng cáo',
            adgroup_pane: {
                add_copies_to: 'Thêm bản sao cho',
                edit_ad: 'Chỉnh sửa quảng cáo',
                edit_adgroup: 'Chỉnh sửa nhóm quảng cáo',
                edit_campaign: 'Chỉnh sửa chiến dịch',
                number_copies: 'Số lượng bản sao'
            },
            adgroup_status_account_penalized: 'Tài khoản bị phạt',
            adgroup_status_ad_group_out_of_budget:
                'Nhóm quảng cáo hết ngân sách',
            adgroup_status_adgroup_deleted: 'Đã xóa nhóm quảng cáo',
            adgroup_status_adgroup_inactive: 'Nhóm quảng cáo không hoạt động',
            adgroup_status_frozen: 'Nhóm quảng cáo bị đóng băng',
            adgroup_status_asset_unavailable_or_authorization_revoked:
                'Nội dung không khả dụng hoặc ủy quyền bị thu hồi',
            adgroup_status_campaign_deleted: 'Chiến dịch đã bị xóa',
            adgroup_status_campaign_inactive: 'Chiến dịch không hoạt động',
            adgroup_status_contract_has_not_taken_effect:
                'Hợp đồng chưa có hiệu lực',
            adgroup_status_disapproved: 'Bị từ chối',
            adgroup_status_edited_for_review: 'Đã chỉnh sửa để xem xét',
            adgroup_status_expired: 'Hết hạn',
            adgroup_status_in_review: 'Đang xem xét',
            adgroup_status_LIVE_not_started: 'LIVE chưa bắt đầu',
            adgroup_status_no_creatives: 'Không có quảng cáo',
            adgroup_status_not_approved: 'Không được chấp thuận',
            adgroup_status_outside_of_schedule:
                'Thời gian theo lịch trình vẫn chưa bắt đầu',
            adgroup_status_payment_unsuccessful_or_insufficient_balance:
                'Thanh toán không thành công hoặc số dư không đủ',
            adgroup_status_pixel_unauthorized: 'Pixel trái phép',
            adgroup_status_qualification_needed: 'Cần trình độ',
            adgroup_status_unable_to_convert_video_upload_again:
                'Không thể chuyển đổi video, hãy tải lên lại',
            adjust_bid: 'Điều chỉnh giá thầu',
            adjust_daily_budget: 'Điều chỉnh ngân sách hằng ngày',
            adjust_lifetime_budget: 'Điều chỉnh tổng ngân sách',
            all_active_ad_under_xxx_adgroup_has_selected:
                'Quảng cáo đang hoạt động trong {xxx} nhóm quảng cáo',
            all_active_ad_under_xxx_campaign_has_selected:
                'Quảng cáo đang hoạt động trong {xxx} chiến dịch',
            all_active_adgroup_under_xxx_campaign_has_selected:
                'Nhóm quảng cáo đang hoạt động trong {xxx} chiến dịch',
            all_day: 'Cả ngày',
            all_inactive_ad_under_xxx_adgroup_has_selected:
                'Quảng cáo đang không hoạt động trong {xxx} nhóm quảng cáo',
            all_inactive_ad_under_xxx_campaign_has_selected:
                'Quảng cáo đang không hoạt động trong {xxx} chiến dịch',
            all_inactive_adgroup_under_xxx_campaign_has_selected:
                'Nhóm quảng cáo đang không hoạt động trong {xxx} chiến dịch',
            all_objects: 'Tất cả đối tượng',
            also_notify_me:
                'Cũng thông báo cho tôi nếu các điều kiện không được đáp ứng',
            any_price: 'Giá bất kỳ',
            app_promotion: 'Quảng cáo ứng dụng',
            apply_rule: 'Áp dụng quy tắc cho',
            auto_ads: 'Tối ưu QC theo CPA',
            auto_max_cost: 'Chi phí tối đa',
            auto_max_cost_action: 'Hành động khi vượt quá chi phí',
            auto_max_cost_bid: 'Chi phí cho tự động',
            auto_max_cpa: 'CPA tối đa',
            auto_min_cpa: 'CPA tối thiểu',
            auto_refresh: 'Tự động cập nhật',
            automatic_cpa_optimization: 'Cấu hình Tự động tối ưu QC theo CPA',
            automatic_live_optimization: 'Cấu hình Tự động tối ưu QC Live',
            automatic_targeting: 'Tự động đặt mục tiêu',
            available_audience: 'Đối tượng khả dụng',
            awareness: 'Nhận thức',
            belong_to_atosa: 'Thuộc về Atosa',
            bid: 'Điều chỉnh giá thầu',
            bid_control: 'Kiểm soát giá thầu',
            bid_strategy: 'Chiến lược giá thầu',
            bid_strategy_bid_cap: 'Giới hạn giá thầu',
            bid_strategy_cost_cap: 'Giới hạn chi phí',
            bid_strategy_lowest_cost: 'Chi phí thấp nhất',
            bidding_optimization: 'Đặt giá thầu & Tối ưu hóa',
            billing_event: 'Sự kiện thanh toán',
            budget_total_rate: 'Tổng ngân sách',
            calculation_method: 'Phương pháp tính toán',
            call_to_action_apply_now: 'Áp dụng ngay',
            call_to_action_book_now: 'Đặt lịch ngay',
            call_to_action_contact_us: 'Liên hệ',
            call_to_action_download_now: 'Tải xuống ngay',
            call_to_action_experience_now: 'Trải nghiệm ngay',
            call_to_action_get_quote: 'Được trích dẫn',
            call_to_action_get_showtimes: 'Nhận lịch chiếu',
            call_to_action_get_tickets_now: 'Nhận vé ngay',
            call_to_action_install_now: 'Cài đặt ngay',
            call_to_action_interested: 'Quan tâm',
            call_to_action_join_this_hastag: 'Tham gia hastag này',
            call_to_action_learn_more: 'Học thêm',
            call_to_action_listen_now: 'Lắng nghe ngay',
            call_to_action_order_now: 'Đặt hàng ngay',
            call_to_action_play_game: 'Chơi trò chơi',
            call_to_action_preorder_now: 'Đặt mua ngay',
            call_to_action_read_more: 'Đọc thêm',
            call_to_action_shoot_with_this_effect: 'Chụp với hiệu ứng này',
            call_to_action_shop_now: 'Mua hàng ngay',
            call_to_action_sign_up: 'Đăng nhập',
            call_to_action_subscribe: 'Đăng ký',
            call_to_action_view_now: 'Xem ngay',
            call_to_action_view_profile: 'Xem thông tin',
            call_to_action_view_video_with_this_effect:
                'Xem video với hiệu ứng này',
            call_to_action_visit_store: 'Ghé cửa hàng',
            call_to_action_watch_live: 'Theo dõi trực tiếp',
            call_to_action_watch_now: 'Theo dõi ngay',
            campaign_budget_optimization: 'Tối ưu hóa ngân sách chiến dịch',
            campaign_name: 'Tên chiến dịch',
            campaign_status_account_in_review: 'Tài khoản đang được xem xét',
            campaign_status_account_not_approved:
                'Tài khoản không được phê duyệt',
            campaign_status_account_penalized: 'Tài khoản bị phạt',
            campaign_status_campaign_out_of_budget: 'Chiến dịch hết ngân sách',
            campaign_status_contract_has_not_taken_effect:
                'Hợp đồng chưa có hiệu lực',
            carriers: 'Nhà điều hành mạng',
            isp: 'Nhà cung cấp dịch vụ internet',
            catalog_sales: 'Bán hàng theo danh mục',
            click_tracking_url: 'URL nhấp chuột theo dõi',
            column_achieve_level: 'Đạt cấp (Không trùng lặp)',
            column_achieve_level_rate:
                'Tỷ lệ chuyển đổi đạt cấp (Không trùng lặp)',
            column_add_billing: 'Thêm thanh toán',
            column_add_billing_rate: 'Tỷ lệ thêm thanh toán',
            column_add_payment_info:
                'Thêm thông tin thanh toán (Không trùng lặp)',
            column_add_payment_info_rate:
                'Tỷ lệ thêm thông tin thanh toán (Không trùng lặp)',
            column_add_to_wishlist: 'Thêm vào danh sách mong muốn',
            column_add_to_wishlist_rate: 'Tỷ lệ thêm vào danh sách mong muốn',
            column_anchor_click_rate: 'Tỷ lệ nhấp vào neo',
            column_anchor_clicks: 'Lượt nhấp vào neo',
            column_app_event_add_to_cart: 'Thêm vào giỏ hàng (Không trùng lặp)',
            column_app_event_add_to_cart_rate:
                'Tỷ lệ thêm vào giỏ hàng (Không trùng lặp)',
            column_app_install: 'Cài đặt ứng dụng',
            column_average_video_play: 'Thời xem xem video trung bình',
            column_average_video_play_per_user:
                'Thời gian xem video trung bình của mỗi người',
            column_avg_value_per_pageview: 'column_avg_value_per_pageview',
            column_button_click: 'Nhấp nút',
            column_button_click_rate: 'Tỷ lệ nhấp nút',
            column_checkout: 'Thanh toán (Không trùng lặp)',
            column_checkout_rate: 'Tỷ lệ thanh toán (Không trùng lặp)',
            column_clicks: 'Lượt nhấp',
            column_clicks_on_hashtag_challenge:
                'Lượt nhấp vào Hashtag challenge',
            column_clicks_on_music_disc: 'Lượt nhấp vào đĩa nhạc',
            column_comments: 'Lượt bình luận nhờ trả phí',
            column_complete_payment: 'Lượt bắt đầu thanh toán',
            column_complete_payment_rate: 'Tỷ lệ hoàn tất thanh toán',
            column_complete_payment_roas: 'ROAS hoàn tất thanh toán',
            column_complete_tutorial: 'Hoàn tất phần hướng dẫn',
            column_complete_tutorial_rate: 'Tỷ lệ hoàn tất thanh toán',
            column_conversion: 'Lượt chuyển đổi',
            column_conversion_rate: 'CVR',
            column_cost_per_1000_reached:
                'Chi phí cho tiếp cận 1000 người dùng',
            column_cost_per_achieve_level: 'Chi phí cho mỗi đạt cấp',
            column_cost_per_add_billing: 'Chi phí cho mỗi thêm thoanh toán',
            column_cost_per_add_payment_info:
                'Chi phí cho mỗi thêm thông tin toán (Không trùng lặp)',
            column_cost_per_add_to_wishlist:
                'Chi phí cho mỗi thêm vào danh sách mong muốn',
            column_cost_per_app_event_add_to_cart:
                'Chi phí cho mỗi thêm vào giỏ hàng (Không trùng lặp)',
            column_cost_per_app_install: 'Chi phí cài đặt ứng dụng',
            column_cost_per_button_click: 'Chi phí cho mỗi lượt nhấp',
            column_cost_per_checkout: 'Chi phí thanh toán (Không trùng lặp)',
            column_cost_per_complete_payment:
                'Chi phí cho mỗi hoàn thành thanh toán',
            column_cost_per_complete_tutorial:
                'Chi phí cho mỗi hoàn thành hướng dẫn',
            column_cost_per_conversion: 'CPA',
            column_cost_per_create_gamerole: 'Chi phí cho mỗi tạo vai trò',
            column_cost_per_create_group: 'Chi phí cho mỗi tạo nhóm',
            column_cost_per_cta_app_install:
                'Chi phí cho mỗi lượt cài đặt ứng dụng theo CTA',
            column_cost_per_cta_conversion:
                'Chi phí cho mỗi lượt chuyển đổi theo CTA',
            column_cost_per_cta_purchase: 'Chi phí mỗi giao dịch theo VTA',
            column_cost_per_cta_registration:
                'Chi phí mỗi lượt đăng ký theo CTA',
            column_cost_per_custom_app_event:
                'column_cost_per_custom_app_event',
            column_cost_per_custom_page_event:
                'column_cost_per_custom_page_event',
            column_cost_per_download_start: 'Chi phí cho mỗi tải xuống',
            column_cost_per_form: 'Chi phí cho mỗi gửi biểu mẫu',
            column_cost_per_in_app_ad_click:
                'Chi phí cho mỗi nhấp vào quảng cáo trong ứng dụng (Không trùng lặp)',
            column_cost_per_in_app_ad_impr:
                'Chi phí cho mỗi lần hiển thị quảng cáo trong ứng dụng',
            column_cost_per_initiate_checkout:
                'Chi phí cho mỗi lượt bắt đầu thanh toán',
            column_cost_per_ix_button_click_count:
                'column_cost_per_ix_button_click_count',
            column_cost_per_ix_page_view_count:
                'column_cost_per_ix_page_view_count',
            column_cost_per_ix_product_click_count:
                'column_cost_per_ix_product_click_count',
            column_cost_per_join_group: 'Chi phí cho mỗi thêm vào nhóm',
            column_cost_per_landing_page_view:
                'column_cost_per_landing_page_view',
            column_cost_per_launch_app: 'Chi phí cho mỗi lượt chạy ứng dụng',
            column_cost_per_loan_apply:
                'Chi phí cho mỗi lượt phê duyệt khoản vay',
            column_cost_per_loan_credit: 'column_cost_per_loan_credit',
            column_cost_per_loan_disbursement:
                'column_cost_per_loan_disbursement',
            column_cost_per_login: 'Chi phí cho mỗi lượt đăng nhập',
            column_cost_per_next_day_open:
                'Chi phí mỗi lượt giữ chân người dùng trong ngày 1 (Không trùng lặp)',
            column_cost_per_offline_contact_event:
                'column_cost_per_offline_contact_event',
            column_cost_per_offline_form_event:
                'column_cost_per_offline_form_event',
            column_cost_per_offline_shopping_event:
                'Chi phí cho mỗi lần hoàn tất thanh toán (Ngoại tuyến)',
            column_cost_per_offline_subscribe_event:
                'column_cost_per_offline_subscribe_event',
            column_cost_per_on_web_add_to_wishlist:
                'Chi phí cho mỗi sự kiện thêm vào danh sách mong muốn (Trên trang)',
            column_cost_per_on_web_order:
                'Chi phí mỗi lượt đặt hàng (Trên trang)',
            column_cost_per_on_web_subscribe:
                'Chi phí cho mỗi lượt đăng ký (Trên trang)',
            column_cost_per_online_consult: 'column_cost_per_online_consult',
            column_cost_per_onsite_add_billing:
                'Chi phí cho mỗi lượt thêm thanh toán (Trên trang)',
            column_cost_per_onsite_add_to_wishlist:
                'Chi phí mối lượt thêm vào danh sách mong muốn (Tại chỗ)',
            column_cost_per_onsite_download_start:
                'Chi phí cho mỗi lượt tải xuống',
            column_cost_per_onsite_form:
                'Chi phí mối lượt gửi biểu mẫu (Tại chỗ)',
            column_cost_per_onsite_initiate_checkout_count:
                'column_cost_per_onsite_initiate_checkout_count',
            column_cost_per_onsite_on_web_cart:
                'Chi phí cho mỗi lượt thêm vào giỏ hàng (Tại chỗ)',
            column_cost_per_onsite_on_web_detail:
                'Chi phí cho mỗi lượt xem trang (Tại chỗ)',
            column_cost_per_onsite_shopping: 'column_cost_per_onsite_shopping',
            column_cost_per_page_browse_view:
                'column_cost_per_page_browse_view',
            column_cost_per_page_event_search:
                'column_cost_per_page_event_search',
            column_cost_per_pageview: 'column_cost_per_pageview',
            column_cost_per_product_details_page_browse:
                'column_cost_per_product_details_page_browse',
            column_cost_per_purchase: 'Chi phí mua hàng (Không trùng lặp)',
            column_cost_per_ratings: 'column_cost_per_ratings',
            column_cost_per_registration: 'Chi phí đăng ký (Không trùng lặp)',
            column_cost_per_result: 'Chi phí mỗi kết quả',
            column_cost_per_sales_lead: 'column_cost_per_sales_lead',
            column_cost_per_search: 'Chi phí cho mỗi lượt tìm kiếm',
            column_cost_per_spend_credits: 'column_cost_per_spend_credits',
            column_cost_per_start_trial: 'column_cost_per_start_trial',
            column_cost_per_subscribe: 'Chi phí cho mỗi lượt đăng ký',
            column_cost_per_total_achieve_level:
                'column_cost_per_total_achieve_level',
            column_cost_per_total_add_to_wishlist:
                'column_cost_per_total_add_to_wishlist',
            column_cost_per_total_app_event_add_to_cart:
                'Chi phí thêm vào giỏ hàng',
            column_cost_per_total_checkout: 'Chi phí tổng thanh toán',
            column_cost_per_total_complete_tutorial:
                'column_cost_per_total_complete_tutorial',
            column_cost_per_total_create_gamerole:
                'column_cost_per_total_create_gamerole',
            column_cost_per_total_create_group:
                'column_cost_per_total_create_group',
            column_cost_per_total_in_app_ad_click:
                'column_cost_per_total_in_app_ad_click',
            column_cost_per_total_in_app_ad_impr:
                'column_cost_per_total_in_app_ad_impr',
            column_cost_per_total_join_group:
                'column_cost_per_total_join_group',
            column_cost_per_total_launch_app:
                'column_cost_per_total_launch_app',
            column_cost_per_total_loan_apply:
                'column_cost_per_total_loan_apply',
            column_cost_per_total_loan_credit:
                'column_cost_per_total_loan_credit',
            column_cost_per_total_loan_disbursement:
                'column_cost_per_total_loan_disbursement',
            column_cost_per_total_login: 'column_cost_per_total_login',
            column_cost_per_total_next_day_open:
                'Chi phí mỗi lượt giữ chân người dùng trong ngày 1',
            column_cost_per_total_purchase: 'Chi phí mua hàng',
            column_cost_per_total_ratings: 'column_cost_per_total_ratings',
            column_cost_per_total_registration: 'Chi phí cho mỗi lượt đăng ký',
            column_cost_per_total_sales_lead:
                'column_cost_per_total_sales_lead',
            column_cost_per_total_search: 'column_cost_per_total_search',
            column_cost_per_total_spend_credits:
                'column_cost_per_total_spend_credits',
            column_cost_per_total_start_trial:
                'column_cost_per_total_start_trial',
            column_cost_per_total_subscribe: 'column_cost_per_total_subscribe',
            column_cost_per_total_unlock_achievement:
                'column_cost_per_total_unlock_achievement',
            column_cost_per_total_view_content:
                'Chi phí cho mỗi lần hiển thị quảng cáo trong ứng dụng',
            column_cost_per_unique_custom_app_event:
                'column_cost_per_unique_custom_app_event',
            column_cost_per_unlock_achievement:
                'column_cost_per_unlock_achievement',
            column_cost_per_user_registration:
                'column_cost_per_user_registration',
            column_cost_per_view_content:
                'Chi phí cho mỗi lần hiển thị quảng cáo trong ứng dụng (Không trùng lặp)',
            column_cost_per_vta_app_install:
                'Chi phí cho mỗi lượt cài đặt ứng dụng theo VTA',
            column_cost_per_vta_conversion:
                'Chi phí cho mỗi lượt chuyển đổi theo VTA',
            column_cost_per_vta_purchase: 'Chi phí mỗi giao dịch theo VTA',
            column_cost_per_vta_registration:
                'Chi phí mỗi lượt đăng ký theo VTA',
            column_cost_per_web_event_add_to_cart:
                'column_cost_per_web_event_add_to_cart',
            column_cost_total_add_payment_info:
                'Chi phí cho mỗi thêm thông tin toán',
            column_countdown_sticker_recall_clicks:
                'Số lượt nhấp gọi lại nhãn dãn đếm ngược',
            column_cpc: 'CPC',
            column_cpm: 'CPM',
            column_create_gamerole: 'Tạo vai trò',
            column_create_gamerole_rate: 'Tỷ lệ tạo vai trò',
            column_create_group: 'Tạo nhóm',
            column_create_group_rate: 'Tỷ lệ tạo nhóm',
            column_cta_app_install: 'Lượt cài đặt ứng dụng theo CTA',
            column_cta_conversion: 'Lượt chuyển đổi theo CTA',
            column_cta_purchase: 'Mua hàng theo CTA',
            column_cta_registration: 'Lượt đăng ký theo CTA',
            column_ctr: 'CTR',
            column_custom_app_event_rate: 'column_custom_app_event_rate',
            column_custom_app_events: 'column_custom_app_events',
            column_custom_app_events_value: 'column_custom_app_events_value',
            column_custom_page_event_rate: 'column_custom_page_event_rate',
            column_custom_page_events: 'column_custom_page_events',
            column_custom_page_events_value: 'column_custom_page_events_value',
            column_download_start: 'Lượt tải xuống',
            column_download_start_rate: 'Tỷ lệ lượt tải xuống',
            column_duet_clicks: 'Lượt nhấp nút Duet',
            column_engaged_view: 'Lượt xem trong 6 giây (Tập trung)',
            column_engaged_view_15s: 'Lượt xem trong 15 giây (Tập trung)',
            column_engagement_rate: 'Tỷ lệ tương tác',
            column_engagements: 'Tổng tỷ lệ tương tác',
            column_follows: 'Người theo dõi nhờ trả phí',
            column_form: 'Gửi biểu mẫu',
            column_form_rate: 'Tỷ lệ gửi biểu mẫu',
            column_frequency: 'Tần suất',
            column_impressions: 'Số lần hiển thị',
            column_in_app_ad_click:
                'Lượt nhấp vào quảng cáo trong ứng dụng (Không trùng lặp)',
            column_in_app_ad_click_rate:
                'Tỷ lệ nhấp vào quảng cáo trong ứng dụng (Không trùng lặp)',
            column_in_app_ad_impr: 'column_in_app_ad_impr',
            column_in_app_ad_impr_rate: 'column_in_app_ad_impr_rate',
            column_initiate_checkout: 'Bắt đầu thanh toán',
            column_initiate_checkout_rate: 'Tỷ lệ bắt đầu thanh toán',
            column_interactive_add_on_activity_clicks:
                'Số lượt nhấp vào hoạt động trong phần bổ trợ tương tác',
            column_interactive_add_on_destination_clicks:
                'Số lượt nhấp vào điểm đến trong phần bổ trợ tương tác',
            column_interactive_add_on_impressions:
                'Số lần hiển thị phần bổ trợ tương tác',
            column_interactive_add_on_option_a_clicks:
                'Số lượt nhấp vào tùy chọn A trong phần bổ trợ tương tác',
            column_interactive_add_on_option_b_clicks:
                'Số lượt nhấp vào tùy chọn B trong phần bổ trợ tương tác',
            column_ix_button_click_count: 'column_ix_button_click_count',
            column_ix_button_click_count_rate:
                'column_ix_button_click_count_rate',
            column_ix_page_duration_avg:
                'Thời gian xem trải nghiệm tức thì trung bình',
            column_ix_page_view_count: 'column_ix_page_view_count',
            column_ix_page_view_count_rate: 'column_ix_page_view_count_rate',
            column_ix_page_viewrate_avg:
                'Phần trăm xem thành phần video trong trải nghiệm tức thì',
            column_ix_product_click_count: 'column_ix_product_click_count',
            column_ix_product_click_count_rate:
                'column_ix_product_click_count_rate',
            column_ix_video_views:
                'Lượt xem thành phần video trong trải nghiệm tức thì',
            column_ix_video_views_p100:
                'Lượt xem 100% thành phần video trong trải nghiệm tức thì',
            column_ix_video_views_p25:
                'Lượt xem 25% thành phần video trong trải nghiệm tức thì',
            column_ix_video_views_p50:
                'Lượt xem 50% thành phần video trong trải nghiệm tức thì',
            column_ix_video_views_p75:
                'Lượt xem 75% thành phần video trong trải nghiệm tức thì',
            column_join_group: 'Thêm vào nhóm',
            column_join_group_rate: 'Tỷ lệ thêm vào nhóm',
            column_landing_page_view_rate: 'column_landing_page_view_rate',
            column_launch_app: 'Chạy ứng dụng',
            column_launch_app_rate: 'Tỷ lệ thêm vào nhóm',
            column_likes: 'Lượt thích nhờ trả phí',
            column_live_effective_views: 'Lượt xem LIVE hiệu quả',
            column_live_product_clicks: 'Lượt nhấp vào sản phẩm trong LIVE',
            column_live_unique_views: 'Lượt xem LIVE không trùng lặp',
            column_live_views: 'Lượt xem LIVE',
            column_loan_apply: 'Phê duyệt khoản vay',
            column_loan_apply_rate: 'Tỷ lệ phê duyệt khoản vay',
            column_loan_credit: 'column_loan_credit',
            column_loan_credit_rate: 'column_loan_credit_rate',
            column_loan_disbursement: 'column_loan_disbursement',
            column_loan_disbursement_rate: 'column_loan_disbursement_rate',
            column_login: 'Đăng nhập',
            column_login_rate: 'Tỷ lệ đăng nhập',
            column_next_day_open:
                'Lượt giữ chân người dùng trong ngày 1 (Không trùng lặp)',
            column_next_day_open_rate:
                'Tỉ lệ lượt giữ chân người dùng trong ngày 1 (Không trùng lặp)',
            column_offline_contact_event_rate:
                'column_offline_contact_event_rate',
            column_offline_contact_events: 'Tổng Liên hệ (Ngoại tuyến)',
            column_offline_contact_events_value:
                'column_offline_contact_events_value',
            column_offline_form_event_rate: 'column_offline_form_event_rate',
            column_offline_form_events: 'column_offline_form_events',
            column_offline_form_events_value:
                'Tổng Giá trị Biểu mẫu Gửi (Ngoại tuyến)',
            column_offline_shopping_event_rate:
                'Tỷ lệ thanh toán hoàn thành (Ngoại tuyến) (%)',
            column_offline_shopping_events:
                'Tổng số tiền hoàn tất (Ngoại tuyến)',
            column_offline_shopping_events_roas:
                'Hoàn thành ROAS thanh toán (Ngoại tuyến)',
            column_offline_shopping_events_value:
                'Tổng giá trị thanh toán hoàn thành (Ngoại tuyến)',
            column_offline_subscribe_event_rate:
                'column_offline_subscribe_event_rate',
            column_offline_subscribe_events: 'column_offline_subscribe_events',
            column_offline_subscribe_events_value:
                'column_offline_subscribe_events_value',
            column_on_web_add_to_wishlist:
                'Thêm vào danh sách mong muốn (Trên trang)',
            column_on_web_add_to_wishlist_per_click:
                'column_on_web_add_to_wishlist_per_click',
            column_on_web_order: 'Lượt đặt hàng (Trên trang)',
            column_on_web_order_rate: 'Tỷ lệ đặt hàng (Trên trang)',
            column_on_web_subscribe: 'Đăng ký (Trên trang)',
            column_on_web_subscribe_per_click:
                'column_on_web_subscribe_per_click',
            column_online_consult: 'column_online_consult',
            column_online_consult_rate: 'column_online_consult_rate',
            column_onsite_add_billing: 'Thêm thanh toán (Tại chỗ)',
            column_onsite_add_billing_rate: 'Tỷ lệ thêm thanh toán (Tại chỗ)',
            column_onsite_add_to_wishlist:
                'Thêm vào danh sách mong muốn (Tại chỗ)',
            column_onsite_add_to_wishlist_rate:
                'Tỷ lệ thêm vào danh sách mong muốn (Tại chỗ)',
            column_onsite_download_start: 'Lượt tải xuống (Tại chỗ)',
            column_onsite_download_start_rate: 'Tỷ lệ lượt tải xuống (Tại chỗ)',
            column_onsite_form: 'Gửi biểu mẫu (Tại chỗ)',
            column_onsite_form_rate: 'Tỷ lệ gửi biểu mẫu (Tại chỗ)',
            column_onsite_initiate_checkout_count:
                'Bắt đầu thanh toán (Mua sắm)',
            column_onsite_initiate_checkout_count_rate:
                'column_onsite_initiate_checkout_count_rate',
            column_onsite_on_web_cart: 'column_onsite_on_web_cart',
            column_onsite_on_web_cart_rate: 'column_onsite_on_web_cart_rate',
            column_onsite_on_web_detail: 'Lượt xem trang sản phẩm (Mua sắm)',
            column_onsite_on_web_detail_rate:
                'column_onsite_on_web_detail_rate',
            column_onsite_shopping: 'Lượt mua hàng (Mua sắm)',
            column_onsite_shopping_rate: 'column_onsite_shopping_rate',
            column_onsite_shopping_roas: 'ROAS (Mua sắm)',
            column_onsitevalue_per_user_registration_shopping_roas:
                'column_onsitevalue_per_user_registration_shopping_roas',
            column_page_browse_view: 'column_page_browse_view',
            column_page_browse_view_rate: 'column_page_browse_view_rate',
            column_page_event_search: 'column_page_event_search',
            column_page_event_search_rate: 'column_page_event_search_rate',
            column_pageview_rate: 'column_pageview_rate',
            column_product_details_page_browse:
                'column_product_details_page_browse',
            column_product_details_page_browse_rate:
                'column_product_details_page_browse_rate',
            column_profile_visits: 'Lượt truy cập hồ sơ nhờ trả phí',
            column_profile_visits_rate: 'Tỷ lệ truy cập hồ sơ nhờ trả phí',
            column_purchase: 'Mua hàng (Không trùng lặp)',
            column_purchase_rate: 'Tỷ lệ mua hàng (Không trùng lặp)',
            column_ratings: 'column_ratings',
            column_ratings_rate: 'column_ratings_rate',
            column_reach: 'Tiếp cận',
            column_real_time_app_install:
                'Cài đặt ứng dụng theo thời gian thực',
            column_real_time_app_install_cost:
                'Chi phí cài đặt ứng dụng theo thời gian thực',
            column_real_time_conversion: 'Chuyển đổi theo thời gian thực',
            column_real_time_conversion_rate: 'CRV theo thời gian thực',
            column_real_time_cost_per_conversion: 'CPA theo thời gian thực',
            column_real_time_cost_per_result:
                'Chi phí kết quả theo thời gian thực',
            column_real_time_result: 'Kết quả theo thời gian thực',
            column_real_time_result_rate: 'Tỷ lệ kết quả theo thời gian thực',
            column_registration: 'Đăng ký (Không trùng lặp)',
            column_registration_rate: 'Tỷ lệ đăng ký (Không trùng lặp)',
            column_result: 'Kết quả',
            column_result_rate: 'Tỷ lệ kết quả',
            column_sales_lead: 'column_sales_lead',
            column_sales_lead_rate: 'column_sales_lead_rate',
            column_search: 'Lượt tìm kiếm',
            column_search_rate: 'Tỷ lệ lượt tìm kiếm',
            column_shares: 'Lượt chia sẻ nhờ trả phí',
            column_sound_usage_clicks: 'Lượt nhấp nút sử dụng âm thanh',
            column_spend: 'Tổng chi phí',
            column_spend_credits: 'column_spend_credits',
            column_spend_credits_rate: 'column_spend_credits_rate',
            column_start_trial: 'column_start_trial',
            column_start_trial_rate: 'column_start_trial_rate',
            column_stitch_clicks: 'Lượt nhấp nút Stitch',
            column_subscribe: 'Đăng ký',
            column_subscribe_rate: 'Tỷ lệ đăng ký',
            column_total_achieve_level: 'Tổng lượt đạt cấp',
            column_total_achieve_level_value: 'Tổng giá trị đạt cấp',
            column_total_active_pay_roas: 'Tổng ROAS mua hàng',
            column_total_add_billing_value: 'Tổng giá trị lượt thêm thanh toán',
            column_total_add_payment_info:
                'Tổng lượt thêm thông tin thanh toán',
            column_total_add_to_wishlist:
                'Tổng lượt thêm vào danh sách mong muốn',
            column_total_add_to_wishlist_value:
                'Tổng giá trị thêm vào danh sách mong muốn',
            column_total_app_event_add_to_cart: 'Tổng thêm vào giỏ hàng',
            column_total_app_event_add_to_cart_value:
                'Tổng giá trị thêm vào giỏ hàng',
            column_total_button_click_value: 'column_total_button_click_value',
            column_total_checkout: 'Tổng thanh toán',
            column_total_checkout_value: 'Tổng giá trị thanh toán',
            column_total_complete_payment_rate:
                'column_total_complete_payment_rate',
            column_total_complete_tutorial: 'Tổng hoàn thành hướng dẫn',
            column_total_complete_tutorial_value:
                'Tổng giá trị hoàn thành hướng dẫn',
            column_total_create_gamerole: 'Tổng tạo vai trò',
            column_total_create_gamerole_value: 'Tổng giá trị tạo vai trò',
            column_total_create_group: 'Tổng lượt tạo nhóm',
            column_total_create_group_value: 'Tổng giá trị lượt tạo nhóm',
            column_total_download_start_value: 'Tổng giá trị lượt tải xuống',
            column_total_form_value: 'Tổng giá trị gửi biểu mẫu',
            column_total_in_app_ad_click: 'column_total_in_app_ad_click',
            column_total_in_app_ad_click_value:
                'Tổng giá trị lượt nhấp vào quảng cáo trong ứng dụng',
            column_total_in_app_ad_impr: 'column_total_in_app_ad_impr',
            column_total_in_app_ad_impr_value:
                'column_total_in_app_ad_impr_value',
            column_total_initiate_checkout_value:
                'column_total_initiate_checkout_value',
            column_total_join_group: 'column_total_join_group',
            column_total_join_group_value: 'Tổng giá trị lượt tạo nhóm',
            column_total_landing_page_view: 'column_total_landing_page_view',
            column_total_launch_app: 'Tổng lượt chạy ứng dụng',
            column_total_loan_apply: 'Tổng lượt phê duyệt khoản vay',
            column_total_loan_credit: 'column_total_loan_credit',
            column_total_loan_disbursement: 'column_total_loan_disbursement',
            column_total_login: 'Tổng lượt đăng nhập',
            column_total_next_day_open: 'Lượt giữ chân người dùng trong ngày 1',
            column_total_on_web_add_to_wishlist_value:
                'Tông lượng thêm vào danh sách mong muốn (Trên trang)',
            column_total_on_web_order_value:
                'Tổng giá trị đặt hàng (Trên trang)',
            column_total_on_web_subscribe_value:
                'column_total_on_web_subscribe_value',
            column_total_online_consult_value:
                'column_total_online_consult_value',
            column_total_onsite_add_billing_value:
                'Tổng giá trị thêm thanh toán (Tại chỗ)',
            column_total_onsite_add_to_wishlist_value:
                'Tổng giá trị thêm vào danh sách mong muốn (Tại chỗ)',
            column_total_onsite_form_value:
                'Tổng giá trị gửi biểu mẫu (Tại chỗ)',
            column_total_onsite_initiate_checkout_count_value:
                'column_total_onsite_initiate_checkout_count_value',
            column_total_onsite_on_web_cart_value:
                'column_total_onsite_on_web_cart_value',
            column_total_onsite_on_web_detail_value:
                'column_total_onsite_on_web_detail_value',
            column_total_onsite_shopping_value: 'Doanh thu gộp (Mua sắm)',
            column_total_page_browse_view_value:
                'column_total_page_browse_view_value',
            column_total_page_event_search_value:
                'column_total_page_event_search_value',
            column_total_pageview: 'column_total_pageview',
            column_total_product_details_page_browse_value:
                'column_total_product_details_page_browse_value',
            column_total_purchase: 'Tổng mua hàng',
            column_total_purchase_value: 'Tổng giá trị mua hàng',
            column_total_ratings: 'column_total_ratings',
            column_total_ratings_value: 'column_total_ratings_value',
            column_total_registration: 'Tổng lượt đăng ký',
            column_total_sales_lead: 'column_total_sales_lead',
            column_total_sales_lead_value: 'column_total_sales_lead_value',
            column_total_search: 'Tổng lượt tìm kiếm',
            column_total_spend_credits: 'column_total_spend_credits',
            column_total_spend_credits_value:
                'column_total_spend_credits_value',
            column_total_start_trial: 'column_total_start_trial',
            column_total_subscribe: 'Tổng lượt đăng ký',
            column_total_subscribe_value: 'Tổng giá trị đăng ký',
            column_total_unlock_achievement: 'column_total_unlock_achievement',
            column_total_unlock_achievement_value:
                'column_total_unlock_achievement_value',
            column_total_user_registration_value:
                'column_total_user_registration_value',
            column_total_value_per_pageview: 'column_total_value_per_pageview',
            column_total_view_content:
                'Tổng số lần hiển thị quảng cáo trong ứng dụng',
            column_total_view_content_value:
                'Tổng giá trị lần hiển thị quảng cáo trong ứng dụng',
            column_total_web_event_add_to_cart_value:
                'column_total_web_event_add_to_cart_value',
            column_unique_custom_app_events: 'column_unique_custom_app_events',
            column_unlock_achievement: 'column_unlock_achievement',
            column_unlock_achievement_rate: 'column_unlock_achievement_rate',
            column_user_registration: 'column_user_registration',
            column_user_registration_rate: 'column_user_registration_rate',
            column_value_per_add_billing: 'Giá trị mỗi lượt',
            column_value_per_button_click: 'column_value_per_button_click',
            column_value_per_checkout: 'column_value_per_checkout',
            column_value_per_complete_payment:
                'Giá trị mỗi lượt hoàn thành thanh toán',
            column_value_per_custom_app_event:
                'column_value_per_custom_app_event',
            column_value_per_custom_page_event:
                'column_value_per_custom_page_event',
            column_value_per_download_start: 'column_value_per_download_start',
            column_value_per_form: 'Giá trị mỗi lượt gửi biểu mẫu',
            column_value_per_initiate_checkout:
                'column_value_per_initiate_checkout',
            column_value_per_offline_contact_event:
                'column_value_per_offline_contact_event',
            column_value_per_offline_form_event:
                'Giá trị mỗi biểu mẫu gửi (Ngoại tuyến)',
            column_value_per_offline_shopping_event:
                'Giá trị trên mỗi khoản thanh toán hoàn chỉnh (Ngoại tuyến)',
            column_value_per_offline_subscribe_event:
                'column_value_per_offline_subscribe_event',
            column_value_per_on_web_add_to_wishlist:
                'Giá trị mỗi lượt thêm vào danh sách mong muốn (Trên trang)',
            column_value_per_on_web_order:
                'Gí trị mỗi lượt đặt hàng (Trên trang)',
            column_value_per_on_web_subscribe:
                'Giá trị mỗi lượt đăng ký (Trên trang)',
            column_value_per_online_consult: 'column_value_per_online_consult',
            column_value_per_onsite_add_billing:
                'Giá trị mỗi lượt thêm thanh toán (Tại chỗ)',
            column_value_per_onsite_add_to_wishlist:
                'Giá trị mỗi lượt thêm vào danh sách mong muốn (Tại chỗ)',
            column_value_per_onsite_form:
                'Giá trị mỗi lượt gửi biễu mẫu (Tại chỗ)',
            column_value_per_onsite_initiate_checkout_count:
                'column_value_per_onsite_initiate_checkout_count',
            column_value_per_onsite_on_web_cart:
                'column_value_per_onsite_on_web_cart',
            column_value_per_onsite_on_web_detail:
                'column_value_per_onsite_on_web_detail',
            column_value_per_onsite_shopping:
                'column_value_per_onsite_shopping',
            column_value_per_page_browse_view:
                'column_value_per_page_browse_view',
            column_value_per_page_event_search:
                'column_value_per_page_event_search',
            column_value_per_product_details_page_browse:
                'column_value_per_product_details_page_browse',
            column_value_per_total_achieve_level:
                'column_value_per_total_achieve_level',
            column_value_per_total_add_to_wishlist:
                'column_value_per_total_add_to_wishlist',
            column_value_per_total_app_event_add_to_cart:
                'Chi phí tổng thêm vào giỏ hàng',
            column_value_per_total_complete_tutorial:
                'column_value_per_total_complete_tutorial',
            column_value_per_total_create_gamerole:
                'column_value_per_total_create_gamerole',
            column_value_per_total_create_group:
                'column_value_per_total_create_group',
            column_value_per_total_in_app_ad_click:
                'column_value_per_total_in_app_ad_click',
            column_value_per_total_in_app_ad_impr:
                'column_value_per_total_in_app_ad_impr',
            column_value_per_total_join_group:
                'column_value_per_total_join_group',
            column_value_per_total_purchase: 'Chi phí cho mỗi lượt mua hàng',
            column_value_per_total_ratings: 'column_value_per_total_ratings',
            column_value_per_total_sales_lead:
                'column_value_per_total_sales_lead',
            column_value_per_total_spend_credits:
                'column_value_per_total_spend_credits',
            column_value_per_total_subscribe: 'Giá trị mỗi lần đăng ký',
            column_value_per_total_unlock_achievement:
                'column_value_per_total_unlock_achievement',
            column_value_per_total_view_content:
                'Giá trị mỗi lần hiển thị quảng cáo trong ứng dụng',
            column_value_per_web_event_add_to_cart:
                'column_value_per_web_event_add_to_cart',
            column_video_play_actions: 'Số lượt xem video',
            column_video_views_p100: 'Số lượt xem 100% thời lượng video',
            column_video_views_p25: 'Số lượt xem 25% thời lượng video',
            column_video_views_p50: 'Số lượt xem 50% thời lượng video',
            column_video_views_p75: 'Số lượt xem 75% thời lượng video',
            column_video_watched_2s: 'Số lượt xem video 2 giây',
            column_video_watched_6s: 'Số lượt xem video 6 giây',
            column_view_content:
                'Số lần hiển thị quảng cáo trong ứng dụng (Không trùng lặp)',
            column_view_content_rate:
                'Tỷ lệ số lần hiển thị quảng cáo trong ứng dụng (Không trùng lặp)',
            column_vta_app_install: 'Lượt cài đặt ứng dụng theo VTA',
            column_vta_conversion: 'Lượt chuyển đổi theo VTA',
            column_vta_purchase: 'Mua hàng theo VTA',
            column_vta_registration: 'Lượt đăng ký theo VTA',
            column_web_event_add_to_cart: 'column_web_event_add_to_cart',
            column_web_event_add_to_cart_rate:
                'column_web_event_add_to_cart_rate',
            community_interaction: 'Tương tác cộng đồng',
            complete_payment: 'Lượt bắt đầu thanh toán',
            condition_and_action: 'Điều kiện & Hành động',
            config_all: 'Cấu hình tất cả',
            config_auto_ads: 'Cấu hình quảng cáo tự động',
            config_live_ads: 'Cấu hình quảng cáo live',
            config_type: 'Kiểu cấu hình',
            connection_type: 'Kiểu kết nối',
            consideration: 'Xem xét',
            content_exclusions: 'Loại trừ nội dung',
            copy_adgroup_with_new_attr:
                'Sao chép nhóm quảng cáo mới cùng với thuộc tính này',
            cost_per_1000_reached: 'Chi phí tiếp cận 1000 người',
            cost_per_result: 'Chi phí cho mỗi kết quả',
            create_campaign_success: 'Quý khách đã tạo chiến dịch thành công',
            create_identity: 'Tạo danh tính',
            create_new: 'Tạo mới',
            create_new_adgroup_from_created_campaign: 'Thêm nhóm quảng cáo',
            create_other_campaign: 'Tạo chiến dịch',
            desc_create_new_adgroup_from_created_campaign:
                'Ấn "Thêm nhóm quảng cáo" để tạo thêm nhóm quảng cáo từ chiến dịch vừa tạo',
            desc_create_other_campaign:
                'Ấn "Tạo chiến dịch" để tạo mới chiến dịch',
            create_rule: 'Tạo mới quy tắc',
            create_split_test: 'Tạo thử nghiệm phân tách',
            creator_interactions: 'Tương tác với người sáng tạo',
            creator_interactions_followed: 'Đã theo dõi',
            creator_interactions_viewed_profile: 'Xem thông tin hồ sơ',
            custom_column_attribution: 'Phân bổ',
            custom_column_basic_data: 'Dữ liệu cơ bản',
            custom_column_engagement: 'Hoạt động tương tác',
            custom_column_in_app_event: 'Sự kiện trong ứng dụng',
            custom_column_interactive_add_on: 'Phần bổ trợ tương tác',
            custom_column_live: 'LIVE',
            custom_column_offline_event: 'Sự kiện ngoại tuyến',
            custom_column_on_site_event: 'Sự kiện tại website',
            custom_column_page_event: 'Sự kiện (Trên trang)',
            custom_column_video_play: 'Phát video',
            custom_targeting: 'Tùy chỉnh mục tiêu',
            daily_budget_spending_rate: 'Tỷ lệ chi tiêu ngân sách hằng ngày',
            dayparting: 'Thời gian trong ngày',
            delivery_type: 'Loại phân phối',
            demographics: 'Nhân khẩu học',
            desc_app_promotion:
                'Thu hút thêm người dùng cài đặt và tương tác với ứng dụng của quý khách.',
            desc_auto_refresh: 'Tự động cập nhật sau mỗi khoảng thời gian',
            desc_automatic_targeting:
                'Tối ưu hóa nhắm mục tiêu dựa trên mục tiêu quảng cáo, nội dung quảng cáo và dữ liệu chiến dịch trong quá khứ.',
            desc_campaign_budget_optimization:
                'Tự động tối ưu hóa phân bổ ngân sách của quý khách. quý khách sẽ có một số hạn chế đối với chiến lược giá thầu và mục tiêu tối ưu hóa của mình.',
            desc_catalog_sales:
                'Phân phối quảng cáo sản phẩm được cá nhân hóa cho những người dùng phù hợp nhất.',
            desc_community_interaction:
                'Nhận thêm người theo dõi hoặc thông tin người dùng.',
            desc_content_exclusions:
                'Bộ lọc danh mục chỉ có sẵn với những quảng cáo trên trang "Dành cho quý khách" tại các địa điểm giới hạn. Hãy chọn cấp độ phù hợp nhất với thương hiệu của quý khách.',
            desc_create_campaign_success:
                'Để thuận lợi trong việc tạo chiến dịch , quý khách có thể chọn những thao tác sau :',
            desc_create_new_campaign:
                'Hãy tạo chiến dịch mới và bắt đầu tăng trưởng trên TikTok ngay hôm nay!',
            desc_create_split_test:
                'Nhận thêm thông tin chi tiết về quảng cáo của quý khách bằng cách thử nghiệm phân tách chiến lược của quý khách.',
            desc_creator_interaction:
                'Nhắm mục tiêu người dùng dựa trên cách họ tương tác với người sáng tạo trên TikTok.',
            desc_creator_interactions:
                'Xác định cách người dùng đã tương tác với người sáng tạo',
            desc_custom_target:
                'Quý khách có thể tạo mẫu và tái sử dụng lại , giảm thời gian thao tác cũng như tối ưu quảng cáo dựa vào mẫu hiệu quả đã lưu',
            desc_custom_targeting:
                'Đặt tiêu chí theo cách thủ công cho loại người dùng mà quý khách muốn hiển thị quảng cáo của mình.',
            desc_following_hashtags:
                'Nhắm mục tiêu người dùng dựa trên cách họ đã tương tác với các thẻ bắt đầu bằng hashtag trên TikTok.',
            desc_full_inventory:
                'Quảng cáo của quý khách có thể xuất hiện bên cạnh một số nội dung có các chủ đề trưởng thành.*',
            desc_interest_keywords:
                'Quý khách có thể sử dụng từ khóa gốc để nhận danh sách các từ khóa quan tâm được đề xuất mà quý khách có thể sử dụng trong các nhóm quảng cáo của mình',
            desc_interests:
                'Nhắm mục tiêu người dùng dựa trên sở thích chung hoặc ý định mua hàng của họ.',
            desc_lead_generation:
                'Thu thập khách hàng tiềm năng cho doanh nghiệp của quý khách',
            desc_limited_inventory:
                'Quảng cáo của quý khách sẽ xuất hiện bên cạnh nội dung không chứa các chủ đề trưởng thành.',
            desc_live_shopping:
                'Sử dụng hình thức truyền phát theo thời gian thực để quảng cáo và bán sản phẩm',
            desc_max_create_xxx_ad:
                'Quý khách có thể tạo tối đa {xxx} quảng cáo trong nhóm quảng cáo TopView.',
            desc_max_create_xxx_adgroup: 'Tối đa {xxx} nhóm quảng cáo',
            desc_pacing_mode_fast:
                'Hệ thống giúp quý khách chi tiêu ngân sách, nhận kết quả nhắm mục tiêu nhanh nhất có thể và tối đa hóa hiệu quả tiếp cận đối tượng của quý khách.',
            desc_pacing_mode_smooth:
                'Ngân sách và quảng cáo của quý khách được phân phối đồng đều dựa trên lưu lượng truy cập kinh doanh trong quảng cáo đã chọn Giai đoạn. Nếu lưu lượng truy cập lớn, quảng cáo của quý khách sẽ được gửi thường xuyên hơn và ngược lại.',
            desc_reach: 'Hiển thị quảng cáo của quý khách cho số người tối đa.',
            desc_recommended_interest_keyword:
                'Những từ khóa này do Tiktok và Atosa phân tích , chọn lọc',
            desc_schedule_from_end: 'Chạy nhóm quảng cáo trong phạm vi ngày',
            desc_schedule_from_end_shopping_live:
                'Đặt thời lượng cho quảng cáo LIVE',
            desc_schedule_from_now:
                'Chạy nhóm quảng cáo liên tục sau thời gian bắt đầu theo lịch trình',
            desc_set_campaign_budget:
                'Tất cả các nhóm quảng cáo của quý khách trong chiến dịch sẽ dừng khi quý khách đã đạt đến giới hạn ngân sách hàng ngày hoặc trọn đời.',
            desc_PRODUCT_SALES:
                'Thu hút người dùng mua sản phẩm tại cửa hàng của quý khách trên TikTok',
            desc_shop_settings_identity:
                'Chọn một tài khoản TikTok làm danh tính của quý khách. quý khách có thể sử dụng bài viết hoặc Video TRỰC TIẾP từ tài khoản này dưới dạng quảng cáo.',
            desc_shopping_ads_type:
                'Quảng cáo sẽ chỉ chạy trong khoảng thời gian đã lên lịch và khi đang phát LIVE.',
            desc_single_video:
                'Tạo quảng cáo bằng một video hoặc nhiều hình ảnh.',
            desc_standard_inventory:
                'Quảng cáo của quý khách sẽ xuất hiện bên cạnh nội dung phù hợp với hầu hết các thương hiệu và có thể chứa một số chủ đề trưởng thành.',
            desc_traffic:
                'Tiếp cận nhiều người đến trang web hoặc ứng dụng của quý khách.',
            desc_use_video_live:
                'Video TRỰC TIẾP trên TikTok sẽ được sử dụng làm quảng cáo của quý khách.',
            desc_user_comment:
                'Chúng tôi khuyên quý khách nên bật nhận xét của người dùng để giúp quảng cáo của quý khách đạt được nhiều hiển thị và chuyển đổi hơn. quý khách có thể ẩn, ghim, và trả lời bình luận bằng các công cụ quản lý bình luận của chúng tôi.',
            desc_video_download:
                'Người dùng ở Nhật Bản sẽ không thể tải xuống quảng cáo video của quý khách.\n Người dùng chỉ có thể tải xuống các bài đăng trên Spark Ad\n nếu đã bật cài đặt này trong cả Trình quản lý quảng cáo và TikTok.',
            desc_video_interaction:
                'Nhắm mục tiêu người dùng dựa trên cách họ đã tương tác với video trên TikTok.',
            desc_video_interactions:
                'Xác định cách người dùng đã tương tác với video',
            desc_video_sharing:
                'Bật cài đặt này để người dùng có thể chia sẻ video của quý khách trên TikTok.\n Nếu nút chuyển đổi bị tắt, người dùng sẽ không thể chia sẻ video của quý khách qua Instagram,\n tin nhắn trực tiếp, SMS, Facebook, Twitter, email hoặc bằng cách sao chép liên kết.\n Các tùy chọn chia sẻ khác sẽ vẫn khả dụng.',
            desc_video_shopping:
                'Sử dụng video hoặc hình ảnh để quảng cáo sản phẩm của bạn',
            desc_video_views:
                'Nhận được nhiều lượt xem và tương tác hơn cho quảng cáo video của quý khách.',
            desc_view_data_audience:
                'Lưu ý: Các giá trị này được ước tính. Dữ liệu đối tượng sẽ mất trung bình 8 giờ để xử lý trước khi thông tin cập nhật được phát hành.',
            desc_website_conversions:
                'Thúc đẩy các hành động có giá trị trên trang web của quý khách.',
            destination_website: 'Website đích',
            device_model: 'Tên thiết bị',
            device_price: 'Giá thiết bị',
            edit_auto_ads: 'Chỉnh sửa quảng cáo tự động',
            edit_live_ads: 'Chỉnh sửa quảng cáo Live',
            end_time: 'Giờ kết thúc',
            exceed_duration_xxx_days:
                'Thời lượng đã chọn không thể vượt quá {xxx} ngày',
            external_event: 'Sự kiện ngoài',
            external_website: 'Trang web bên ngoài',
            followers: 'Theo dõi',
            following_hashtags: 'Tương tác với hastag',
            frequency: 'Tần số',
            frequency_cap: 'Giới hạn tần số',
            frequency_show_ads_3_times_7_days:
                'Hiển thị quảng cáo không quá 3 lần mỗi 7 ngày',
            frequency_show_ads_custom_frequency_cap:
                'Tùy chỉnh giới hạn tần suất',
            frequency_show_ads_more_than_once_day:
                'Hiển thị quảng cáo không quá một lần một ngày',
            from_library: 'Thư viện',
            full_inventory: 'Đầy đủ',
            get_notification_every_time:
                'Quý khách sẽ nhận được thông báo mỗi khi quy tắc chạy, ngay cả khi các điều kiện không được đáp ứng.',
            impression_tracking_url: 'URL hiển thị theo dõi',
            in_app_event: 'Sự kiện trong App',
            initiate_checkout: 'Lượt bắt đầu thanh toán',
            install: 'Cài đặt',
            interest_keywords: 'Từ khóa quan tâm',
            interests: 'Mối quan tâm',
            interests_and_behaviours: 'Sở thích & Hành vi',
            kind_config_default: 'Thiết lập thủ công',
            kind_config_list: 'Thiết lập tự động',
            lead_generation: 'Khách hàng tiềm năng',
            lifetime_budget_spending_rate: 'Tỷ lệ chi tiêu tổng ngân sách',
            limited_inventory: 'Giới hạn',
            list_custom_target: 'Danh sách mẫu',
            list_custom_target_adgroup: 'Danh sách mẫu nhóm quảng cáo',
            live_ads: 'Tối ưu QC Live',
            live_shopping: 'Quảng cáo mua sắm qua LIVE',
            live_video: 'Video trực tiếp',
            manage_comment: 'Quản lý bình luận',
            manage_rule: 'Quản lý quy tắc',
            max_cost: 'Chi phí tối đa',
            max_cpa: 'CPA tối đa',
            maximum_bid_cap: 'Giới hạn Giá thầu tối đa',
            maximum_daily_budget_cap: 'Giới hạn Ngân sách hằng ngày tối đa',
            maximum_lifetime_budget_cap: 'Giới hạn Tổng ngân sách tối đa',
            message_adgroup_doesnt_have_any_ads:
                'Nhóm quảng cáo này chưa có quảng cáo nào , quý khách cần tạo mới quảng cáo',
            message_adgroup_identity_doesnt_connect:
                'Quý khách không còn quyền truy cập vào tài khoản TikTok được sử dụng trong quảng cáo này. Để tiếp tục chỉnh sửa, hãy chọn danh tính và tài liệu sáng tạo mới hoặc đăng ký lại để có quyền truy cập',
            message_adgroup_store_doesnt_connect:
                'Quý khách không còn có quyền truy cập vào cửa hàng được sử dụng trong quảng cáo này. Để tiếp tục chỉnh sửa, hãy chọn một cửa hàng hoặc đăng ký lại quyền truy cập',
            message_clear_ad_list:
                'Cài đặt cho tất cả các quảng cáo mà quý khách đã tạo sẽ bị xóa. quý khách có muốn tiếp tục không?',
            message_confirm_delete:
                'Quý khách có chắc sẽ xóa cấu hình này không?',
            message_create_config_auto_failed: 'Tạo cấu hình tự động thất bại',
            message_create_config_auto_success:
                'Tạo cấu hình tự động thành công',
            message_delete_config_auto_failed: 'Xóa cấu hình tự động thất bại',
            message_delete_config_auto_success:
                'Xóa cấu hình tự động thành công',
            message_edit_config_auto_failed:
                'Chỉnh sửa cấu hình tự động thất bại',
            message_edit_config_auto_success:
                'Chỉnh sửa cấu hình tự động thành công',
            message_live_ads_cant_duplicated:
                'Quảng cáo mua sắm trực tiếp không thể trùng lặp',
            message_update_status_xxx_failed:
                'Thay đổi trạng thái của {xxx} thất bại',
            message_update_status_xxx_success:
                'Thay đổi trạng thái của {xxx} thành công',
            message_update_mess_status_success:
                'Thay đổi trạng thái hàng loạt thành công',
            message_delete_xxx_failed: 'Xóa {xxx} thất bại',
            message_delete_xxx_success: 'Xóa {xxx} thành công',
            minimum_bid: 'Giá thầu tối thiểu',
            minimum_daily_budget: 'Ngân sách hằng ngày tối thiểu',
            minimum_lifetime_budget: 'Tổng ngân sách tối thiểu',
            noti_desc_full_inventory_1:
                '*Tất cả khoảng không quảng cáo trong Khoảng không quảng cáo đầy đủ, tiêu chuẩn và có giới hạn loại trừ nội dung mà TikTok biết là vi phạm Nguyên tắc cộng đồng, Chính sách sở hữu trí tuệ và Điều khoản dịch vụ của TikTok.',
            noti_desc_full_inventory_2:
                'Chúng tôi sẽ nỗ lực hợp lý để loại trừ nội dung phù hợp với lựa chọn cấp độ của quý khách nhưng chúng tôi không thể đảm bảo tính chính xác của việc loại trừ nội dung của chúng tôi.',
            notification_schedule: 'Lịch trình thông báo',
            notify_me: 'Thông báo cho tôi',
            notify_me_every_time: 'Gửi thông báo cho tôi mỗi lần',
            objective_type: 'Mục tiêu quảng cáo',
            of_each_object: 'Từng đối tượng',
            on_going: 'Hằng ngày',
            once_in_1_w: 'Một tuần một lần',
            once_in_24_h: '24 giờ một lần',
            once_in_48_h: '48 giờ một lần',
            only_once: 'Chỉ một lần',
            optimization_event: 'Sự kiện tối ưu hóa',
            optimization_goal: 'Mục tiêu tối ưu hóa',
            optimization_goal_engaged_view: '6-second views (Focused view)',
            optimization_goal_lead_generation: 'Khách hàng tiềm năng',
            optimization_goal_minimum_roas: 'ROAS thấp',
            optimization_goal_highest_roas: 'Tổng doanh thu cao nhất',
            optimization_goal_desc_highest_roas:
                'Chi tiêu đầy đủ ngân sách của quý khách trong khi tối đa hóa giá trị mua hàng.',
            os_versions: 'Hệ điều hành',
            overall_trends: 'Xu hướng chung',
            pixel_event_add_payment_info: 'Thêm thông tin thanh toán',
            pixel_event_add_to_cart: 'Thêm vào giỏ hàng',
            pixel_event_add_to_wishlist: 'Thêm vào danh sách yêu thích',
            pixel_event_click_button: 'Nhấp vào nút',
            pixel_event_complete_payment: 'Hoàn thành thanh toán',
            pixel_event_complete_registration: 'Hoàn thành đăng ký',
            pixel_event_consult: 'Tham khảo',
            pixel_event_download: 'Tải về',
            pixel_event_initiate_checkout: 'Bắt đầu thanh toán',
            pixel_event_on_search: 'Đang tìm kiếm',
            pixel_event_place_an_order: 'Thay đổi đơn hàng',
            pixel_event_submit_form: 'Gửi biểu mẫu',
            pixel_event_subscribe: 'Đăng ký',
            pixel_event_view_content: 'Xem nội dung',
            placements: 'Vị trí',
            please_budget_than_min:
                'Hãy nhập ngân sách cao hơn hoặc bằng {budget}',
            please_input_ad_format_landing_page_url:
                'Vui lòng nhập đúng định dạng url trang đích của quảng cáo',
            please_input_ad_landing_page_url:
                'Vui lòng nhập url trang đích của quảng cáo',
            please_input_ad_name: 'Hãy nhập tên quảng cáo',
            please_input_ad_text: 'Hãy nhập nội dung quảng cáo',
            please_input_adgroup_name: 'Hãy nhập tên nhóm quảng cáo',
            please_input_bid_price: 'Hãy nhập giới hạn giá thầu',
            please_input_bid_price_lowser_budget:
                'Giá thầu phải bé hơn ngân sách',
            please_input_campaign_name: 'Hãy nhập tên chiến dịch',
            please_input_conversion_bid: 'Hãy nhập giới hạn chi phí',
            please_input_end_time_more_than_start_time:
                'Thời gian kết thúc phải lớn hơn thời gian bắt đầu',
            please_input_identity_display_name: 'Hãy nhập tên danh tính',
            please_input_start_time_more_than_current:
                'Thời gian bắt đầu phải lớn hơn thời gian hiện tại',
            please_input_video: 'Hãy chọn video',
            please_select_app: 'Hãy chọn App',
            please_select_event: 'Hãy chọn sự kiện',
            please_select_identity: 'Hãy chọn danh tính',
            please_select_identity_id: 'Hãy chọn danh tính',
            please_select_input_campaign_name: 'Hãy nhập tên chiến dịch',
            please_select_input_rule_name: 'Hãy nhập tên quy tắc tự động',
            please_select_location: 'Hãy chọn vị trí',
            please_select_pixel: 'Hãy chọn pixel',
            please_select_products: 'Hãy chọn sản phẩm',
            please_vsa_select_max_n_products:
                'Quảng cáo VSA chỉ chọn tối đa {n} sản phẩm',
            please_psa_select_max_n_products:
                'Quảng cáo PSA chỉ chọn tối đa {n} sản phẩm',
            please_select_store_id: 'Hãy chọn cửa hàng',
            post_delivered: 'Bài đăng của quý khách sẽ được gửi từ',
            product_click_in_live: 'Lượt nhấp vào sản phẩm trong phiên LIVE',
            product_post_delivered:
                'Sản phẩm và bài đăng của quý khách sẽ được gửi từ',
            product_sales: 'Doanh số sản phẩm',
            profile_views: 'Lượt truy cập hồ sơ',
            promotion_type: 'Loại khuyến mãi',
            real_time_conversion: 'Số lượt chuyển đổi theo thời gian thực',
            real_time_conversion_rate: 'CVR theo thời gian thực',
            real_time_cost_per_conversion: 'CPA theo thời gian thực',
            real_time_cost_per_result:
                'Chi phí cho mỗi kết quả theo thời gian thực',
            real_time_result: 'Kết quả theo thời gian thực',
            real_time_result_rate: 'Tỷ lệ kết quả theo thời gian thực',
            receive_notifications_by_email:
                'Nhận thông báo qua email khi các điều kiện của quy tắc được đáp ứng.',
            recommended_interest_behavior_categories:
                'Danh mục sở thích và/hoặc hành vi được đề xuất',
            recommended_interest_keyword: 'Từ khóa tìm kiếm nhiều và đang lên',
            result_rate: 'Tỷ lệ kết quả',
            rule_name: 'Tên quy tắc',
            rule_settings: 'Cài đặt quy tắc',
            run_at_specific_times: 'Chạy vào thời gian cụ thể',
            run_daily: 'Chạy hằng ngày',
            run_every_15_minutes: 'Chạy 15 phút 1 lần',
            run_every_30_minutes: 'Chạy 30 phút 1 lần',
            run_rule_every_30_minute:
                'Chạy quy tắc 30 phút một lần trong các khoảng thời gian cụ thể.',
            run_schedule: 'Lịch trình chạy',
            select_at_least_one_category: 'Chọn ít nhất một danh mục',
            select_at_least_one_type_of_action:
                'Chọn ít nhất một loại hành động',
            select_rule_for:
                'Hãy chọn chiến dịch, nhóm quảng cáo hoặc quảng cáo mà quý khách muốn áp dụng quy tắc',
            select_specific_time: 'Chọn thời gian',
            select_target_mode: 'Chọn chế độ',
            select_time_period: 'Chọn khoảng thời gian',
            send_notification_together:
                'Gửi thông báo tổng hợp cho một khoảng thời gian cụ thể',
            set_campaign_budget: 'Đặt ngân sách chiến dịch',
            set_custom_identity: 'Thiết lập danh tính',
            set_tiktok_account_to_identity: 'Bài đăng Tiktok',
            PRODUCT_SALES: 'Doanh số sản phẩm',
            shop_settings: 'Cài đặt cửa hàng',
            single_video: 'Video đơn',
            skip_return_campaign_list: 'Bỏ qua để trở về danh sách chiến dịch',
            specific_price: 'Giá cụ thể',
            standard_inventory: 'Tiêu chuẩn',
            standard_video: 'Video tiêu chuẩn',
            start_time: 'Giờ bắt đầu',
            target_campaigns: 'Chiến dịch đích',
            targeting: 'Mục tiêu',
            targeting_summary: 'Tóm tắt nhóm quảng cáo',
            third_party_tracking_settings: 'Thiết lập theo dõi từ bên thứ 3',
            tiktok_account_hasnt_ads_account:
                'Tài khoản Tiktok này không có tài khoản quảng cáo !!!',
            tiktok_instant_page: 'Trang tức thì Tiktok',
            tiktok_post: 'Bài đăng Tiktok',
            time_of_action: 'Thời gian chạy',
            time_range: 'Khoảng thời gian',
            traffic_landing_page_view: 'Lượt xem trang',
            try_xxx_interest_behavior_categories:
                'Để tiếp cận những người dùng phù hợp hơn, hãy thử {xxx} danh mục sở thích và hành vi này',
            type_live_ads: {
                click: 'click',
                convert: 'convert',
                mt_live_room: 'mt_live_room',
                product_click_in_live: 'product_click_in_live'
            },
            use_account_owned_by_you: 'Sử dụng tài khoản được ủy quyền',
            use_existing: 'Sử dụng hiện tại',
            use_spark_ads: 'Sử dụng tài khoản TikTok để phân phối Spark Ads',
            user_comment: 'Bình luận của người dùng',
            video_download: 'Tải xuống video',
            video_interactions: 'Tương tác video',
            video_interactions_commented: 'Đã bình luận',
            video_interactions_liked: 'Đã thích',
            video_interactions_period:
                'Chọn một khoảng thời gian để bao gồm các hành động từ',
            video_interactions_shared: 'Đã chia sẻ',
            video_interactions_watch_till_end: 'Đã xem hết',
            video_library: 'Thư viện Video',
            video_sharing: 'Chia sẻ video',
            video_shopping: 'Quảng cáo mua sắm qua video',
            video_view: 'Xem video',
            video_views: 'Lượt xem video',
            viewer_retention: 'Giữ chân người xem',
            website_conversions: 'Lượt chuyển đổi trên website',
            xxx_ad_has_selected: 'Đã chọn {xxx} quảng cáo',
            xxx_adgroup_has_selected: 'Đã chọn {xxx} nhóm quảng cáo',
            xxx_campaign_has_selected: 'Đã chọn {xxx} chiến dịch'
        },
        connect_tiktok_account: {
            assign_to_bc: 'Chỉ định cho BC',
            choose_bc: 'Chọn doanh nghiệp',
            connect_for_business: 'Kết nối Tiktok để kinh doanh',
            connect_success: 'Quý khách đã kết nối thành công tài khoản Tiktok',
            message_connect_ads_account_error:
                'Đã có lỗi xảy ra khi tạo tài khoản QC',
            message_connect_ads_account_success: 'Tạo tài khoản QC thành công',
            name_company: 'Tên công ty',
            title: 'Thiết lập Tiktok Doanh nghiệp',
            title_ads_account: 'Tài khoản quảng cáo Tiktok',
            title_business_account: 'Tài khoản Tiktok Doanh nghiệp',
            title_business_center: 'Trung tâm doanh nghiệp'
        },
        login: {
            message_for_reset_password:
                'Quý khách đã thay đổi mật khẩu thành công',
            reset_password:
                'Chào <span class="font-semibold">{display_name}</span>! Hãy thiết lập mật khẩu mới cho tài khoản <span class="font-semibold">{username}</span>',
            policy: 'Bằng cách đăng ký, bạn đồng ý với Atame về <a target="_blank" href="https://atosa.io/policy" class="underline text-brand-atosa">Điều khoản dịch vụ và Chính sách quyền riêng tư</ a>',
            dont_have_an_account: 'Quý khách chưa có tài khoản',
            forgot_password: 'Quên mật khẩu',
            have_an_account: 'Quý khách đã có tài khoản',
            login_now: 'Đăng nhập ngay',
            placeholder: {
                email: 'Email',
                name: 'Họ Tên',
                password: 'Mật khẩu',
                phone: 'Số điện thoại',
                username: 'Tên tài khoản'
            },
            register: 'Đăng ký tài khoản',
            register_now: 'Đăng ký ngay',
            thanks_for_registering:
                'Cảm ơn quý khách! Chúng tôi đã gửi thông tin đăng nhập tới email',
            desc_forget_password:
                'Atame sẽ gửi thông tin đặt lại mật khẩu vào mail của quý khách',
            message_for_forget_password:
                'Atame sẽ gửi thông tin về mail này. Vui lòng đọc thư và làm theo hướng dẫn',
            title: 'Đăng nhập',
            here: 'tại đây',
            join_course: 'Tham gia khóa học Quảng cáo Shopee , Tiktok hiệu quả',
            learn_more:
                'Tìm hiểu thêm về Công cụ hỗ trợ chạy Quảng cáo Shopee an toàn nhất',
            ads_management_toolkit: 'Bộ công cụ Quản lý TikTok Ads',
            seo_keyword_1: 'phần mềm quảng cáo tiktok tự động',
            seo_keyword_2: 'phần mềm hỗ trợ quảng cáo tiktok tự động',
            seo_keyword_3: 'phần mềm hỗ trợ quảng cáo tiktok',
            seo_keyword_4: 'quảng cáo tiktok tự động',
            seo_keyword_5: 'quảng cáo tiktok',
            seo_keyword_6: 'quản lý tài khoản quảng cáo tiktok',
            seo_keyword_7: 'quản lý tài khoản tiktok',
            seo_keyword_8: 'quản lý quảng cáo tiktok',
            seo_keyword_9: 'Phần mềm chạy quảng cáo tiktok'
        },
        member: {
            access_assigned_ad_accounts:
                'Truy cập vào tài khoản nhà quảng cáo được chỉ định',
            ads_manager: 'Trình quản lý quảng cáo',
            change_business_info: 'Thay đổi thông tin doanh nghiệp',
            desc_assign_member_dialog:
                'Nhập địa chỉ email của bất kỳ thành viên nào quý khách muốn mời vào trung tâm doanh nghiệp này và đặt quyền truy cập của họ.',
            email_placeholder:
                'Quý khách có thể nhập nhiều email bằng cách nhấn Enter sau mỗi email.',
            invite: 'Mời thành viên',
            invite_member_successfully: 'Mời thành viên thành công',
            invite_new_member: 'Mời thành viên mới',
            manage_ad_accounts: 'Quản lý tài khoản quảng cáo',
            manage_members: 'Quản lý thành viên',
            pending: 'Chờ xử lý',
            view_business_info: 'Xem thông tin doanh nghiệp'
        },
        not_found: {
            desc: 'Page quý khách tìm kiếm chúng tôi không có'
        },
        onboarding: {
            active_1_1:
                'Chỉ người dùng TikTok For Business mới có thể tạo tài khoản quảng cáo, tạo tài khoản Trung tâm doanh nghiệp TikTok, được chỉ định vai trò và nhận quyền truy cập vào tài sản. quý khách có thể trở thành người dùng TikTok For Business bằng cách đăng ký tại các liên kết sau.',
            active_1_2: 'Kết nối tài khoản Tiktok For Business của quý khách.',
            active_1_3:
                'Chọn tài khoản Tiktok For Business của quý khách để tạo Tài khoản quảng cáo',
            active_2_1:
                'Trung tâm doanh nghiệp là đầu mối trọn bộ cho các tổ chức thuộc mọi quy mô kích hoạt các hoạt động tiếp thị và tiếp cận đối tượng mong muốn theo hướng hợp tác trên TikTok. Nhờ Trung tâm doanh nghiệp, các tổ chức có thể tập trung tài sản và đội ngũ lại ở một nơi, cho phép phân bổ quyền và sử dụng tài sản một cách hiệu quả và an toàn.',
            active_2_2: 'Tạo Trung tâm doanh nghiệp của quý khách.',
            active_2_3:
                'Chọn Trung tâm doanh nghiệp để tạo Tài khoản Quảng cáo mới.',
            active_2_3_2:
                'Chọn Trung tâm doanh nghiệp để chỉ định Tài khoản Quảng cáo mới.',
            active_2_4: 'Nạp tiền vào tài khoản Quảng cáo mới.',
            active_3_1:
                'Tạo tài khoản Nhà quảng cáo trên Atosa sẽ giúp quý khách nạp tiền dễ dàng, nhanh chóng và hiệu quả.',
            active_3_2:
                'Tạo tài khoản Quảng cáo và Chuyển sang bước nạp tiền cho tài khoản.',
            active_3_2_2:
                'Tạo tài khoản Quảng cáo và Chuyển sang bước chỉ định cho Trung tâm kinh doanh (BC).',
            add_balance_now: 'Nạp tiền ngay!',
            complete_onboarding_message:
                'Chúng mừng quý khách đã hoàn thành quá trình tạo Tài khoản QC. Sau đây quý khách hãy nạp tiền vào tài khoản QC để có thể tạo các chiến dịch quảng bá sản phẩm của quý khách!',
            guide: 'Hướng dẫn tạo Tài khoản Nhà quảng cáo'
        },
        package: {
            can_buy_more:
                'Khi mua gói có trả phí , quý khách có thể mua thêm giá trị của tính năng này !',
            buy_more: 'Mua thêm',
            extra_field: {
                max_tiktok_account:
                    'Quản lí tối đa tài khoản tiktok doanh nghiệp',
                max_ad_account:
                    'Mỗi tài khoản tiktok doanh nghiệp tạo mới tối đa tài khoản quảng cáo',
                max_bc: 'Mỗi tài khoản tiktok doanh nghiệp tạo mới tối đa trung tâm doanh nghiệp',
                auto_ads: 'Tạo tối đa quy tắc tự động',
                recharge_online: 'Nạp tiền vào TKQC online',
                recharge_to_ads_account:
                    'Nạp tiền thẳng vào Tài khoản QC dễ dàng và nhanh chóng, phù hợp với Agency',
                clone_campaign: 'Copy Chiến dịch và Nhiều nhóm Quảng cáo',
                analysis_data:
                    'Phân tích chi tiết các thông số của từng chiến dịch',
                permission_second_account: 'Phân quyền tài khoản phụ',
                manage_target: 'Quản lý tệp đối tượng target',
                create_campaigns_in_1_time:
                    'Tạo nhiều quảng cáo, nhóm quảng cáo cùng lúc, và mỗi nhóm thì có mục tiêu và tệp đối tượng riêng biệt',
                edit_copy_campaign:
                    'Khi copy nhiều nhóm quảng cáo, cho phép chỉnh sửa chi tiết từng nhóm quảng cáo',
                compare_data: 'So sánh dữ liệu theo ngày',
                analysis_hourly_data: 'Xem dữ liệu theo giờ trong ngày',
                keyword_page: 'Công cụ từ khóa trong quảng cáo video',
                manager_campaign_template:
                    'Quản lý mẫu nhóm quảng cáo, mẫu nhóm chiến dịch',
                delete_campaign: 'Xóa chiến dịch, nhóm quảng cáo',
                filter_campaign_by_conditions:
                    'Lọc chiến dịch, nhóm quảng cáo theo điều kiện chi tiết hoặc nhiều điều kiẹn',
                auto_refresh_campaign: 'Tự động cập nhật dữ liệu campaign',
                copy_adgroup_to_campaign:
                    'Copy nhiều nhóm quảng cáo (tối đa 5 nhóm) sang nhiều chiến dịch khác nhau',
                internal_account_transfer:
                    'Linh động chuyển tiền giữa các tài khoản quảng cáo',
                create_adgroup_by_campaign:
                    'Tạo nhóm quảng cáo nhanh từ chiến dịch',
                report_by_ads_account:
                    'Báo cáo hiệu quả -> gom nhiều tài khoản quảng cáo',
                crud_target_when_campaign_run:
                    'Thêm bớt nhóm đối tượng khi đang chạy quảng cáo',
                permission_target:
                    'Phân quyền tệp đối tượng theo tài khoản quảng cáo'
            },
            ads_management: 'Thêm , sửa , xóa , nhân bản chiến dịch',
            buy_more_auto_ads: 'Mua thêm để tạo thêm quy tắc tự động',
            buy_more_tiktok_accounts:
                'Mua thêm số lượng quản lý tài khoản tiktok',
            buy_more_ads_accounts:
                'Mua thêm số lượng tạo mới tài khoản quảng cáo của mỗi tài khoản tiktok',
            buy_more_bc:
                'Mua thêm số lượng tạo mới trung tâm doanh nghiệp của mỗi tài khoản tiktok',
            extra_purchase_package: 'Gói mua thêm',
            detailed_analysis:
                'Phân tích chi tiết các thông số của từng chiến dịch',
            manage_maximum_xxx_tiktok_accounts:
                'Quản lý tối đa <span class="font-semibold">{xxx}</span> tài khoản tiktok doanh nghiệp',
            manage_maximum_xxx_ads_accounts:
                'Mỗi tài khoản tiktok doanh nghiệp tạo mới tối đa <span class="font-semibold">{xxx}</span> tài khoản quảng cáo',
            manage_maximum_xxx_bc:
                'Mỗi tài khoản tiktok doanh nghiệp tạo mới tối đa <span class="font-semibold">{xxx}</span> trung tâm doanh nghiệp',
            ad_accounts: 'Tài khoản QC',
            add_balance_easily_etc:
                'Nạp tiền cho Tài khoản QC dễ dàng và nhanh chóng',
            auto_optimize_xxx_ad:
                'Tạo tối đa <span class="font-semibold">{xxx}</span> quy tắc tự động',
            auto_optimize_ad_etc:
                'Tự động tối ưu Quảng cáo và nhiều tiện ích khác',
            change_plan: 'Xem thêm gói khác',
            choose_plan: 'Mua gói',
            copy_campaign_etc: 'Copy Chiến dịch và Nhiều nhóm Quảng cáo',
            create_maximum: 'Tạo tối đa',
            current_plan: 'Gói sản phẩm đang dùng',
            enjoy_all_the_feature: 'Trải nghiệm tất cả tính năng',
            register_from: 'Đăng ký từ',
            license_to: 'Thời hạn đến',
            manage_maximum: 'Quản lý tối đa',
            our_best_pricing: 'Gói Sản phẩm Tiktok Ads'
        },
        payment: {
            invoice: 'Xuất hóa đơn',
            tax_code: 'Mã số thuế',
            company_name: 'Tên công ty',
            company_phone: 'Số điện thoại công ty',
            company_address: 'Địa chỉ công ty',
            company_email: 'Email nhận hóa đơn',
            ad_account_list: 'Danh sách Tài khoản quảng cáo',
            ad_account_transaction_history:
                'Lịch sử giao dịch Tài khoản quảng cáo',
            add_balance: 'Nạp tiền',
            add_balance_title: 'Cộng tiền vào Tài khoản Quảng cáo',
            add_balance_to_ads_account: 'Nạp tiền vào TKQC',
            add_balance_to_atosa_account: 'Nạp tiền vào số dư tài khoản Atosa',
            add_fund: 'Cộng tiền',
            add_fund_successfully: 'Cộng tiền thành công',
            add_money_store: 'Nạp tiền',
            add_price_order_cancel_order: 'Hoàn tiền mua gói',
            atosa_account_transaction_history:
                'Lịch sử giao dịch Tài khoản Atosa',
            atosa_balance: 'Số dư tài khoản Atosa',
            cash_amount: 'Số tiền',
            confirm_add_fund_message:
                'Quý khách có muốn nạp tiền vào Tài khoản quảng cáo không?',
            enter_cash_amount: 'Nhập số tiền cần nạp',
            online_recharge: 'Nạp tiền trực tuyến',
            payment_note:
                'Quý khách chỉ có thể nạp tiền vào những Tài khoản Quảng cáo được tạo trên hệ thống ATAME',
            recharge: 'Nạp tiền',
            sub_buy_point: 'Trừ điểm mua gói',
            sub_money_store: 'Trừ tiền',
            sub_price_order: 'Trừ tiền mua gói',
            success_purchased: 'Mua thành công gói',
            transaction_code: 'mã giao dịch',
            your_balance_not_enough: 'Số dư tài khoản của quý khách không đủ'
        },
        shop: {
            please_verify_again: 'Vui lòng làm lại quá trình xác minh',
            message_verify_success: 'Vui lòng làm mới trình duyệt',
            verify_tiktok_shop_success:
                'Quá trình xác minh tài khoản Tiktok shop thành công !',
            verify_tiktok_shop_fail:
                'Quá trình xác minh tài khoản Tiktok shop thất bại !',
            verify_tiktok_business_success:
                'Quá trình xác minh tài khoản Tiktok doanh nghiệp thành công !',
            verify_tiktok_business_fail:
                'Quá trình xác minh tài khoản Tiktok doanh nghiệp thất bại !',
            add_shop: 'Thêm cửa hàng',
            connect_shop: 'Kết nối Tiktok Shop',
            connect_tiktok_account: 'Kết nối tài khoản QC Tiktok',
            connect_tiktok_account_success:
                'Liên kết tài khoản QC Tiktok thành công',
            connect_tiktok_shop_success: 'Kết nối Tiktok Shop thành công',
            dialog_note:
                'Nhập mã cửa hàng của TikTok Shop để thêm TikTok Shop vào Trung tâm doanh nghiệp. Chúng tôi sẽ gửi yêu cầu quyền truy cập qua email cho chủ cửa hàng và các quản trị viên chính hoặc người quản lý quảng cáo.',
            dialog_title: 'Yêu cầu quyền truy cập vào TikTok Shop',
            dont_have_tts: 'Quý khách chưa có Tiktok Shop?',
            enter_shop_code: 'Nhập mã cửa hàng',
            error: 'Đã xảy ra lỗi khi kết nối',
            has_connected: 'Đã kết nối',
            hasnt_connected: 'Chưa kết nối',
            how_find_it: 'Cách tìm mã?',
            how_find_it_message:
                'Tìm mã trong Trung tâm cho người bán hàng trên TikTok Shop > "Tài khoản của tôi" > "Hồ sơ của người bán" > "Thông tin của người bán".',
            how_find_it_title: 'Cách tìm mã',
            // info: 'Để tạo chiến dịch mua tại cửa hàng , quý khách cần làm những bước sau : Gửi yêu cầu quản lý Tiktok Shop -> Xác nhận từ URL -> Kết nối Tiktok Shop -> Kết nối tài khoản QC Tiktok có cùng email với Tiktok Shop',
            info: 'Để tạo chiến dịch mua tại cửa hàng , quý khách cần làm những bước sau : Gửi yêu cầu quản lý Tiktok Shop -> Xác nhận từ URL -> Kết nối Tiktok Shop',
            manage_tiktok_shop: 'Gửi yêu cầu quản lý Tiktok Shop',
            pending: 'Đang chờ xử lý',
            process: 'Đang xử lý',
            register_new_tts: 'Đăng ký Tiktok Shop',
            register_now: 'Đăng ký ngay',
            request_sent: 'Yêu cầu đã được gửi',
            shop_code: 'Mã cửa hàng',
            status_connect: 'Trạng thái kết nối',
            submit_url: 'URL xác nhận',
            success: 'Đã được xác nhận'
        },
        tiktok_account: {
            desc_admin:
                'Quản lý tài chính, cài đặt và quyền của tài khoản quảng cáo.Tạo và chỉnh sửa chiến dịch quảng cáo và xem dữ liệu hiệu suất.',
            desc_analyst: 'Xem quảng cáo và dữ liệu hiệu suất.',
            desc_assign_member_dialog:
                'Chọn các thành viên từ trung tâm doanh nghiệp của quý khách và đặt quyền truy cập mà quý khách muốn cấp cho họ.',
            desc_operator:
                'Tạo và chỉnh sửa chiến dịch quảng cáo và xem dữ liệu hiệu suất.',
            edit_custom_info: 'Chỉnh sửa thông tin tài khoản tiktok',
            message_confirm_delete:
                'Quý khách có chắc xóa tài khoản này không?',
            message_delete_tiktok_account_fail: 'Xóa tài khoản tiktok thất bại',
            message_delete_tiktok_account_success:
                'Xóa tài khoản tiktok thành công',
            title: 'Tài khoản Tiktok'
        },
        tiktok_identity: {
            info: 'Để lấy những video , bài viết trong tài khoản Tiktok của quý khách cho mục địch quảng cáo, hãy kết nối ở đây',
            add_tiktok_account: 'Thêm tài khoản Tiktok',
            confirm_linked_message:
                'Quý khách đã xác thực tài khoản Tiktok rồi đúng không?',
            copy_qr_code: 'Sao chép mã QR',
            dialog_content_1:
                'Ủy quyền cho Trung tâm doanh nghiệp "ATOSA GLOBAL"',
            dialog_content_2: 'Phân phối quảng cáo bằng các bài đăng hiện có',
            dialog_content_3:
                'Truy cập thông tin hồ sơ | Truy cập các video công khai | Truy cập các video có hỗ trợ quảng cáo',
            dialog_content_4: 'Phân phối quảng cáo bằng video LIVE',
            dialog_content_5:
                'Truy cập thông tin hồ sơ | Truy cập các video LIVE',
            dialog_note:
                'Yêu cầu một chủ tài khoản TikTok bất kỳ quét mã QR này để phê duyệt yêu cầu của quý khách. Sau khi được phê duyệt, tài khoản sẽ xuất hiện trong tài sản của quý khách và quý khách có thể cấp quyền truy cập cho các thành viên của mình.',
            dialog_title: 'Yêu cầu quyền truy cập vào Tài khoản TikTok',
            enter_tiktok_account_id: 'Nhập Tiktok ID, ví dụ: @atosa.official',
            get_qr_code: 'Lấy QR Code',
            how_find_it: 'Cách tìm ID?',
            how_find_it_message:
                'Tìm Tiktok ID trong App Tiktok > Hồ sơ > Copy chuỗi bắt đầu với "@". Ví dụ: @atosa.official.',
            how_find_it_title: 'Cách tìm ID',
            scan_code: 'Cách quét mã',
            scan_code_1: '1. Mở ứng dụng TikTok và nhấn vào "Bạn bè"',
            scan_code_2: '2. Nhấn vào , sau đó nhấn vào để quét mã',
            scan_code_3: '3. Ủy quyền cho yêu cầu quyền truy cập',
            scan_code_4:
                '4. Ấn nút "Xác nhận đã liên kết" trên Atosa để xác thực tài khoản Tiktok.',
            tiktok_id: 'Tiktok ID'
        },
        affiliate: {
            code: 'Mã tiếp thị của quý khách',
            desc: 'Để tham gia hệ thống tiếp thị liên kết của Atosa , quý khách chỉ cần thao tác các bước sau',
            desc_1: '1 . Sao chép mã tiếp thị của quý khách',
            desc_2: '2 . Gửi mã tiếp thị tới nhiều người dùng để nhận hoa hồng',
            desc_3: '3 . Người dùng thanh toán dịch vụ của Atosa',
            desc_3_1:
                'Trong trang thanh toán dịch vụ , quý khách hãy hướng dẫn người dùng nhập mã tiếp thị của quý khách vào <b>"Có người giới thiệu của bạn"</b>',
            desc_4: '4 . Hệ thống sẽ tính toán và cộng vào số dư quý khách khi có giao dịch gắn mã tiếp thị của quý khách'
        }
    },
    table: {
        header: {
            action: 'Thao tác',
            ad_group_id: 'ID nhóm quảng cáo',
            ad_group_name: 'Tên nhóm quảng cáo',
            mobile: 'Điện thoại',
            on_off: 'Bật/Tắt',
            total_cost: 'Chi phí',
            username: 'Tài khoản'
        }
    }
}

export default lang
