<template>
    <div class="component-box">
        <p class="mb-8 font-semibold">
            {{ $t('page.campaign.ad_details') }}
        </p>

        <div class="flex flex-col space-y-6">
            <div v-if="objective_type === 'PRODUCT_SALES'">
                <p
                    v-if="
                        selected_item_in_line.extension.shopping_ads_type ===
                        'PRODUCT_SHOPPING_ADS'
                    "
                    class="text-xs"
                >
                    {{ $t('page.campaign.choose_product_from') }}
                    <b>{{ selected_item_in_line.extension.store_name }}</b>
                </p>
                <div v-else class="flex items-center text-xs">
                    <p
                        v-if="
                            selected_item_in_line.extension
                                .shopping_ads_type !== 'LIVE'
                        "
                    >
                        {{ $t('page.campaign.product_post_delivered') }}
                    </p>
                    <p
                        v-if="
                            selected_item_in_line.extension
                                .shopping_ads_type === 'LIVE'
                        "
                    >
                        {{ $t('page.campaign.post_delivered') }}
                    </p>

                    <div
                        class="bg-bg rounded-xl flex items-center pr-2 ml-2 space-x-1"
                    >
                        <el-avatar
                            :size="21"
                            :src="
                                selected_item_in_line.extension.identity
                                    .image_uri
                            "
                        ></el-avatar>
                        <p>
                            {{
                                selected_item_in_line.extension.identity
                                    .display_name
                            }}
                        </p>
                    </div>
                </div>
            </div>

            <div
                v-if="
                    ['PRODUCT_SALES'].every((item) => item !== objective_type)
                "
                class="border-border border-b-[1px]"
            >
                <p class="text-desc-text mb-4 text-xs font-semibold">
                    {{ $t('page.campaign.ad_format') }}
                </p>
                <div
                    v-for="(i, idx) in ad_format_options"
                    :key="idx"
                    class="mt-4 rounded-lg py-4 px-8 flex space-x-4 cursor-pointer border-[1px] box-border"
                    :class="[
                        selected_item.ad_format === i.value
                            ? 'border-brand-atosa bg-white'
                            : 'border-transparent bg-bg hover:bg-border'
                    ]"
                    @click="selected_item.ad_format = i.value"
                >
                    <div class="min-w-10">
                        <font-awesome-icon size="lg" :icon="i.icon" />
                    </div>

                    <div>
                        <p class="mb-1 text-base font-semibold">
                            {{ i.title }}
                        </p>

                        <p class="component-text-desc">
                            {{ i.description }}
                        </p>
                    </div>
                </div>
            </div>

            <div
                v-if="
                    objective_type === 'PRODUCT_SALES' &&
                    selected_item_in_line.extension.shopping_ads_type !== 'LIVE'
                "
            >
                <div class="flex items-center">
                    <p class="text-xs font-semibold">
                        {{ $t('common.products') }}
                    </p>

                    <el-button
                        class="!ml-auto text-xs"
                        type="text"
                        :disabled="
                            !selected_item_in_line.extension.selected_products
                                .length
                        "
                        @click="handleRemoveAllProduct"
                    >
                        {{ $t('common.delete_all') }}</el-button
                    >
                </div>

                <el-alert
                    type="info"
                    class="mb-2"
                    :title="$t('page.campaign.desc_product')"
                >
                </el-alert>

                <el-select
                    v-if="
                        selected_item_in_line.extension.shopping_ads_type ===
                        'PRODUCT_SHOPPING_ADS'
                    "
                    v-model="selected_item_in_line.product_specific_type"
                    size="small"
                    :placeholder="$t('input.placeholder.please_select')"
                    filterable
                    class="w-full mb-2"
                >
                    <el-option
                        v-for="item in product_specific_type_options"
                        :key="item.value"
                        :label="item.title"
                        :value="item.value"
                    >
                    </el-option>
                </el-select>

                <div
                    v-if="selected_item_in_line.product_specific_type !== 'ALL'"
                >
                    <div
                        v-if="
                            selected_item_in_line.extension.selected_products
                                .length
                        "
                        class="overflow-auto pr-2 max-h-[280px] flex flex-col space-y-2 mb-4"
                    >
                        <draggable
                            v-model="
                                selected_item_in_line.extension
                                    .selected_products
                            "
                            :options="{ handle: '.drag-handle' }"
                            class="flex flex-col flex-wrap gap-1"
                        >
                            <div
                                v-for="item in selected_item_in_line.extension
                                    .selected_products"
                                :key="item.item_group_id"
                                class="bg-bg drag-item drag-handle flex w-full p-3 space-x-3 rounded-lg"
                            >
                                <p class="font-semibold cursor-pointer">::</p>

                                <img
                                    :src="item.image_url"
                                    class="w-[60px] h-[60px] object-contain"
                                />

                                <div>
                                    <el-tooltip
                                        class="item"
                                        effect="dark"
                                        :content="item.title"
                                        placement="top"
                                    >
                                        <p
                                            class="truncate font-semibold w-[280px] text-sm"
                                        >
                                            {{ item.title }}
                                        </p>
                                    </el-tooltip>

                                    <p class="text-desc-text mt-1 text-xs">
                                        SPU ID : {{ item.item_group_id }}
                                    </p>

                                    <p
                                        v-if="item.price_list"
                                        class="mt-1 text-xs"
                                    >
                                        {{
                                            showPrice(
                                                item.price_list,
                                                item.currency
                                            )
                                        }}
                                    </p>
                                    <p v-else>
                                        Sale:
                                        {{
                                            p__renderMoneyByCurrency(
                                                item.min_price,
                                                item.currency
                                            )
                                        }}
                                    </p>
                                </div>

                                <el-button
                                    class="!ml-auto self-center"
                                    type="text"
                                    @click="
                                        handleRemoveProduct(item.item_group_id)
                                    "
                                >
                                    {{ $t('common.delete') }}</el-button
                                >
                            </div>
                        </draggable>
                    </div>

                    <el-button
                        icon="el-icon-plus"
                        size="small"
                        @click="is_add_product_drawer_displayed = true"
                    >
                        {{ $t('page.campaign.add_products') }}
                    </el-button>
                </div>
            </div>

            <div
                v-if="
                    objective_type !== 'PRODUCT_SALES' ||
                    (objective_type === 'PRODUCT_SALES' &&
                        selected_item_in_line.extension.shopping_ads_type ===
                            'LIVE' &&
                        selected_item_in_line.extension
                            .shopping_live_ad_setup === 'standard_video') ||
                    (objective_type === 'PRODUCT_SALES' &&
                        selected_item_in_line.extension.shopping_ads_type ===
                            'VIDEO')
                "
            >
                <p class="text-desc-text mb-4 text-xs font-semibold">
                    {{ $t('page.campaign.ad_creative') }}
                </p>

                <div class="flex flex-col space-y-6">
                    <div>
                        <p class="component-label">Video</p>

                        <div
                            v-if="selected_item.extension.video"
                            class="bg-bg flex mb-4 rounded"
                        >
                            <div
                                class="min-w-[72px] w-[72px] h-[72px] bg-bg rounded-xl overflow-hidden relative cursor-pointer"
                                @click="
                                    handleShowInfoVideoDialog(
                                        selected_item.extension.video
                                    )
                                "
                            >
                                <img
                                    v-if="
                                        selected_item.extension.video.poster_url
                                    "
                                    :src="
                                        selected_item.extension.video.poster_url
                                    "
                                    class="object-contain w-full h-full"
                                    alt=""
                                />
                                <img
                                    v-if="
                                        selected_item.extension.video
                                            .video_cover_url
                                    "
                                    :src="
                                        selected_item.extension.video
                                            .video_cover_url
                                    "
                                    class="object-contain w-full h-full"
                                    alt=""
                                />

                                <div
                                    class="top-1/2 left-1/2 absolute -translate-x-1/2 -translate-y-1/2"
                                >
                                    <i
                                        class="el-icon-caret-right text-xl text-white"
                                    ></i>
                                </div>
                            </div>

                            <div class="flex flex-col justify-center space-y-1">
                                <div class="flex items-end space-x-2">
                                    <div
                                        v-if="
                                            selected_item.extension.video
                                                .file_name
                                        "
                                    >
                                        <el-tooltip
                                            class="item"
                                            effect="dark"
                                            :content="
                                                selected_item.extension.video
                                                    .file_name
                                            "
                                            placement="top"
                                        >
                                            <p
                                                class="text-xs truncate max-w-[240px]"
                                            >
                                                {{
                                                    selected_item.extension
                                                        .video.file_name
                                                }}
                                            </p>
                                        </el-tooltip>
                                    </div>

                                    <span class="text-desc-text text-xs">
                                        {{
                                            p__renderDurationToMinute(
                                                selected_item.extension.video
                                                    .duration
                                            )
                                        }}
                                        |
                                        {{
                                            selected_item.extension.video.width
                                        }}
                                        x
                                        {{
                                            selected_item.extension.video.height
                                        }}
                                    </span>
                                </div>
                                <div class="cursor-pointer">
                                    <el-dropdown trigger="click">
                                        <div
                                            class="flex items-center space-x-1"
                                        >
                                            <i
                                                class="el-icon-edit-outline text-sm"
                                            ></i>
                                            <span class="text-xs">
                                                {{ $t('common.update') }}
                                            </span>
                                        </div>
                                        <el-dropdown-menu slot="dropdown">
                                            <el-dropdown-item
                                                @click.native="
                                                    handleChangeVideo
                                                "
                                            >
                                                {{
                                                    selected_item_in_line.video_id
                                                        ? $t(
                                                              'page.campaign.tiktok_post'
                                                          )
                                                        : $t(
                                                              'page.campaign.from_library'
                                                          )
                                                }}
                                            </el-dropdown-item>
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </div>
                            </div>
                        </div>

                        <div>
                            <el-button
                                v-if="
                                    selected_item_in_line.identity_type ===
                                        'BC_AUTH_TT' ||
                                    objective_type === 'PRODUCT_SALES'
                                "
                                icon="el-icon-plus"
                                size="small"
                                @click="is_from_tiktok_post_displayed = true"
                            >
                                {{ $t('page.campaign.tiktok_post') }}
                            </el-button>

                            <el-button
                                v-if="
                                    selected_item_in_line.identity_type !==
                                    'BC_AUTH_TT'
                                "
                                icon="el-icon-plus"
                                size="small"
                                @click="is_from_library_drawer_displayed = true"
                            >
                                {{ $t('page.campaign.from_library') }}
                            </el-button>

                            <el-button
                                v-if="
                                    selected_item_in_line.identity_type !==
                                    'BC_AUTH_TT'
                                "
                                icon="el-icon-upload2"
                                size="small"
                                @click="
                                    is_upload_video_library_drawer_displayed = true
                                "
                            >
                                {{ $t('common.upload_file') }}
                            </el-button>
                        </div>
                    </div>

                    <div id="input_ad_text_wrapper">
                        <p class="component-label">
                            {{ $t('common.text') }}
                        </p>
                        <!-- <div class="flex items-center space-x-2">
                            <el-tooltip
                                class="item"
                                effect="dark"
                                :content="
                                    $t('page.campaign.desc_video_download')
                                "
                                placement="top"
                            >
                                <i
                                    class="header-icon el-icon-info text-desc-text mb-[6px]"
                                ></i>
                            </el-tooltip>
                        </div> -->

                        <el-input
                            id="input_ad_text"
                            v-model="selected_item_in_line.ad_text"
                            type="text"
                            size="small"
                            :placeholder="$t('input.placeholder.please_input')"
                            maxlength="100"
                            show-word-limit
                        >
                        </el-input>
                    </div>

                    <div
                        v-if="
                            objective_type === 'PRODUCT_SALES' &&
                            selected_item_in_line.extension
                                .shopping_ads_type !== 'LIVE'
                        "
                    >
                        <p class="component-label">
                            {{ $t('common.call_to_action') }}
                        </p>

                        <el-select
                            v-model="selected_item_in_line.call_to_action"
                            filterable
                            class="w-full"
                            size="small"
                        >
                            <el-option
                                v-for="item in p__call_to_action_options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </div>
                </div>
            </div>

            <div
                v-if="
                    objective_type === 'PRODUCT_SALES' &&
                    selected_item_in_line.extension.shopping_ads_type ===
                        'LIVE' &&
                    selected_item_in_line.extension.shopping_live_ad_setup ===
                        'live_video'
                "
                class="bg-bg p-6 rounded-lg"
            >
                <p class="text-xs">
                    {{ $t('page.campaign.desc_use_video_live') }}
                </p>
            </div>

            <div
                v-if="
                    ['TRAFFIC', 'WEB_CONVERSIONS'].some(
                        (item) => item === objective_type
                    )
                "
                id="input_ad_landing_page_url_wrapper"
            >
                <p class="component-label">
                    {{ $t('page.campaign.destination_website') }}
                </p>
                <el-input
                    id="input_ad_landing_page_url"
                    v-model="selected_item_in_line.landing_page_url"
                    type="text"
                    size="small"
                    :placeholder="$t('input.placeholder.please_input')"
                >
                </el-input>
            </div>
        </div>

        <AddProductsDrawer
            v-if="
                objective_type === 'PRODUCT_SALES' &&
                selected_item.extension.shopping_ads_type !== 'LIVE'
            "
            :visible.sync="is_add_product_drawer_displayed"
            :tiktok_account_id="tiktok_account_id"
            :selected_products="
                selected_item_in_line.extension.selected_products
            "
            :advertiser_id="advertiser_id"
            :belong_to_atosa="belong_to_atosa"
            :catalog_id="catalog_id"
            :currency="currency"
            :bc_id="bc_id"
            :store_id="selected_item_in_line.extension.store_id"
            :store_authorized_bc_id="
                selected_item_in_line.extension.store_authorized_bc_id
            "
            :max_products="
                selected_item.extension.shopping_ads_type ===
                'PRODUCT_SHOPPING_ADS'
                    ? p__number_max_products_in_product_shopping_ads
                    : p__number_max_products_in_video_shopping_ads
            "
        ></AddProductsDrawer>

        <FromTiktokPostDrawer
            v-if="
                selected_item_in_line.identity_type === 'BC_AUTH_TT' ||
                objective_type === 'PRODUCT_SALES'
            "
            :visible.sync="is_from_tiktok_post_displayed"
            :tiktok_account_id="tiktok_account_id"
            :advertiser_id="advertiser_id"
            :belong_to_atosa="belong_to_atosa"
            :identity_id="selected_item_in_line.extension.identity.identity_id"
            :identity_type="
                selected_item_in_line.extension.identity.identity_type
            "
            :identity_authorized_bc_id="
                selected_item_in_line.extension.identity
                    .identity_authorized_bc_id
            "
            @choose_video_from_tiktok_post="handleChooseVideoFromTiktokPost"
        ></FromTiktokPostDrawer>

        <FromVideoLibraryDrawer
            :visible.sync="is_from_library_drawer_displayed"
            :tiktok_account_id="tiktok_account_id"
            :advertiser_id="advertiser_id"
            :multiple="false"
            :belong_to_atosa="belong_to_atosa"
            @choose_video="handleChooseVideo"
        ></FromVideoLibraryDrawer>

        <UploadVideoLibraryDrawer
            :visible.sync="is_upload_video_library_drawer_displayed"
            :tiktok_account_id="tiktok_account_id"
            :advertiser_id="advertiser_id"
            :belong_to_atosa="belong_to_atosa"
        ></UploadVideoLibraryDrawer>

        <InfoVideoDialog
            :tiktok_account_id="tiktok_account_id"
            :advertiser_id="advertiser_id"
            :visible.sync="show_info_video_dialog"
            :preview_url="preview_url"
            :video_id="video_id"
        ></InfoVideoDialog>
    </div>
</template>

<script>
import FromVideoLibraryDrawer from './from-video-library-drawer'
import UploadVideoLibraryDrawer from './upload-video-library-drawer'
import FromTiktokPostDrawer from './from-tiktok-post-drawer'
import AddProductsDrawer from './add-products-drawer'
import draggable from 'vuedraggable'

export default {
    components: {
        AddProductsDrawer,
        FromVideoLibraryDrawer,
        UploadVideoLibraryDrawer,
        FromTiktokPostDrawer,
        draggable
    },

    props: [
        'items',
        'selected_item',
        'advertiser_id',
        'tiktok_account_id',
        'belong_to_atosa',
        'bc_id',
        'catalog_id',
        'currency',
        'objective_type'
    ],

    data() {
        return {
            show_info_video_dialog: false,
            preview_url: '',
            video_id: '',
            text: '',
            is_upload_video_library_drawer_displayed: false,
            is_from_library_drawer_displayed: false,
            is_add_product_drawer_displayed: false,
            is_from_tiktok_post_displayed: false,
            selected_products: [],
            product_specific_type_options: [
                {
                    title: this.$t('page.campaign.all_products'),
                    description: this.$t('page.campaign.desc_all_products'),
                    value: 'ALL'
                },
                {
                    title: this.$t('page.campaign.specific_products'),
                    description: this.$t(
                        'page.campaign.desc_specific_products'
                    ),
                    value: 'CUSTOMIZED_PRODUCTS'
                }
            ],
            ad_format_options: [
                {
                    title: this.$t('page.campaign.single_video'),
                    description: this.$t('page.campaign.desc_single_video'),
                    value: 'SINGLE_VIDEO',
                    icon: 'fa-solid fa-film'
                }
                // {
                //     title: this.$t('page.campaign.single_image'),
                //     description: this.$t('page.campaign.desc_single_image'),
                //     value: 'SINGLE_IMAGE',
                //     icon: 'fa-solid fa-image'
                // },
                // {
                //     title: this.$t('page.campaign.collection_ads'),
                //     description: this.$t('page.campaign.desc_collection_ads'),
                //     value: 'LIVE_CONTENT',
                //     icon: 'fa-solid fa-cart-shopping'
                // }
            ],

            fields: [
                { id: this.p__renderId(), key: 'location_ids', value: '' },
                { id: this.p__renderId(), key: 'languages', value: '' }
            ]
        }
    },

    computed: {
        selected_item_in_line: {
            get: function () {
                return this.selected_item
            },
            set: function (val) {
                this.$emit('update:selected_item', val)
            }
        },

        items_in_line: {
            get: function () {
                return this.items
            },
            set: function (val) {
                this.$emit('update:items', val)
            }
        }
    },

    watch: {
        'selected_item.extension.use_spark_ads'() {
            this.selected_item_in_line.video_id = ''
            this.selected_item_in_line.tiktok_item_id = ''
            this.selected_item_in_line['extension']['video'] = null
        },

        'selected_item_in_line.product_specific_type'() {
            if (
                this.selected_item_in_line.product_specific_type ===
                'CUSTOMIZED_PRODUCTS'
            ) {
                if (
                    this.selected_item_in_line.extension.selected_products
                        .length
                ) {
                    this.p__event_bus.$emit(
                        'eb_selected_products_for_preview_ad',
                        this.selected_item_in_line.extension
                            .selected_products[0].item_group_id
                    )
                } else {
                    this.p__event_bus.$emit(
                        'eb_selected_products_for_preview_ad',
                        ''
                    )
                }
            }
        }
    },

    mounted() {
        this.p__event_bus.$on('eb_select_products', this.selectProducts)
    },

    destroyed() {
        this.p__event_bus.$off('eb_select_products', this.selectProducts)
    },

    methods: {
        showPrice(price_list, currency) {
            if (price_list.length === 0) {
                return this.p__renderMoneyByCurrency(0, currency)
            }

            if (price_list.length === 1) {
                return this.p__renderMoneyByCurrency(price_list[0], currency)
            }

            const min = Math.min(...price_list)
            const max = Math.max(...price_list)
            if (min === max) {
                return this.p__renderMoneyByCurrency(min, currency)
            }
            return `${this.p__renderMoneyByCurrency(
                min,
                currency
            )} - ${this.p__renderMoneyByCurrency(max, currency)}`
        },

        handleRemoveAllProduct() {
            this.selected_item_in_line.extension.selected_products = []
        },

        handleRemoveProduct(item_group_id) {
            this.selected_item_in_line.extension.selected_products =
                this.selected_item_in_line.extension.selected_products.filter(
                    (product) => product.item_group_id !== item_group_id
                )
        },

        handleShowInfoVideoDialog(video) {
            this.preview_url = ''
            this.video_id = ''

            if (video?.video_id) {
                this.video_id = video.video_id
            } else if (video?.url) {
                this.preview_url = video.url
            }

            this.show_info_video_dialog = true
        },

        handleChooseVideo(info_videos) {
            const video = info_videos[0]

            this.selected_item_in_line.video_id = video.video_id
            this.selected_item_in_line['extension']['video'] = video
        },

        handleChooseVideoFromTiktokPost(info_videos) {
            info_videos.forEach((_, index) => {
                const video = info_videos[index]

                if (index === 0) {
                    this.selected_item_in_line.ad_text = video.text
                    this.selected_item_in_line.tiktok_item_id = video.item_id

                    this.selected_item_in_line['extension']['video'] = {
                        ...video.video_info,
                        file_name: video.text
                    }
                } else {
                    const new_item = JSON.parse(
                        JSON.stringify(this.selected_item_in_line)
                    )
                    new_item.extension.id = this.p__renderId()
                    new_item.ad_text = video.text
                    new_item.tiktok_item_id = video.item_id
                    new_item['extension']['video'] = {
                        ...video.video_info,
                        file_name: video.text
                    }

                    this.items_in_line.push(new_item)
                }
            })
        },

        selectProducts(items) {
            this.selected_item_in_line.extension.selected_products = items.map(
                (item) => ({
                    ...item,
                    id: item.item_group_id
                })
            )
        },

        handleChangeVideo() {
            if (this.selected_item_in_line.video_id) {
                this.is_from_library_drawer_displayed = true
                return
            }

            this.is_from_tiktok_post_displayed = true
        }
    }
}
</script>
