<template>
    <div v-if="selected_item" class="flex h-full space-x-4">
        <div class="min-w-[560px] w-[560px] flex flex-col space-y-4">
            <div id="input_ad_name_wrapper" class="component-box">
                <p class="component-label">
                    {{ $t('common.name') }}
                </p>

                <el-input
                    id="input_ad_name"
                    v-model="selected_item.ad_name"
                    class="flex-1"
                    size="small"
                ></el-input>
            </div>

            <AdSetup
                v-if="
                    objective_type === 'PRODUCT_SALES' &&
                    selected_item.extension.shopping_ads_type === 'LIVE'
                "
                :items.sync="items_in_line"
                :selected_item.sync="selected_item"
                :advertiser_id="advertiser_id"
                :tiktok_account_id="tiktok_account_id"
            ></AdSetup>

            <Identity
                v-if="
                    ['PRODUCT_SALES'].every((item) => item !== objective_type)
                "
                :selected_item.sync="selected_item"
                :objective_type="objective_type"
                :belong_to_atosa="belong_to_atosa"
                :advertiser_id="advertiser_id"
                :tiktok_account_id="tiktok_account_id"
            ></Identity>

            <AdDetails
                :items.sync="items_in_line"
                :selected_item.sync="selected_item"
                :objective_type="objective_type"
                :advertiser_id="advertiser_id"
                :bc_id="bc_id"
                :catalog_id="catalog_id"
                :currency="currency"
                :belong_to_atosa="belong_to_atosa"
                :tiktok_account_id="tiktok_account_id"
            ></AdDetails>

            <Tracking
                v-if="
                    selected_item.extension.shopping_ads_type !==
                    'PRODUCT_SHOPPING_ADS'
                "
                :selected_item.sync="selected_item"
                :advertiser_id="advertiser_id"
                :tiktok_account_id="tiktok_account_id"
                :objective_type="objective_type"
            ></Tracking>

            <div class="component-box invisible !-mt-4"></div>
        </div>

        <div class="h-fit min-w-[280px] w-[280px]" :class="[m__class_top]">
            <div
                v-if="
                    type_form !== 'edit' &&
                    selected_item.extension.shopping_ads_type !==
                        'PRODUCT_SHOPPING_ADS'
                "
                class="w-full"
            >
                <SelectAd
                    :items.sync="items_in_line"
                    :selected_item="selected_item"
                    :handleSelectAd="handleSelectAd"
                    :objective_type="objective_type"
                ></SelectAd>

                <CustomTargetAdgroupBox
                    v-if="
                        ['PRODUCT_SALES'].some(
                            (item) => item !== objective_type
                        )
                    "
                    class="mt-4"
                    :tiktok_account_id="tiktok_account_id"
                    :objective_type="objective_type"
                    :items.sync="items_in_line"
                    :selected_item.sync="selected_item"
                ></CustomTargetAdgroupBox>
            </div>

            <PreviewAd
                class="w-full"
                :objective_type="objective_type"
                :selected_item="selected_item"
            ></PreviewAd>
        </div>
    </div>
</template>

<script>
import Identity from './identity'
import Tracking from './tracking'
import SelectAd from './select-ad'
import AdSetup from './ad-setup'
import AdDetails from './ad-details'
import PreviewAd from './preview-ad'
import CustomTargetAdgroupBox from './custom-target-ad-box'
import stickyTopMixin from '@/plugins/mixins/sticky-top'

export default {
    name: 'EditAdForm',

    components: {
        Identity,
        AdDetails,
        Tracking,
        AdSetup,
        CustomTargetAdgroupBox,
        PreviewAd,
        SelectAd
    },

    mixins: [stickyTopMixin],

    props: [
        'items',
        'advertiser_id',
        'currency',
        'tiktok_account_id',
        'belong_to_atosa',
        'objective_type',
        'catalog_id',
        'bc_id',
        'type_form' //  edit , create
    ],

    data() {
        return {
            selected_item: null
        }
    },

    computed: {
        items_in_line: {
            get: function () {
                return this.items
            },
            set: function (val) {
                this.$emit('update:items', val)
            }
        }
    },

    mounted() {
        this.selected_item = this.items_in_line[0]

        this.p__event_bus.$on('eb_select_first_ad', this.handleSelectFirstAd)
    },

    destroyed() {
        this.p__event_bus.$off('eb_select_first_ad', this.handleSelectFirstAd)
    },

    methods: {
        handleSelectFirstAd() {
            this.selected_item = this.items_in_line[0]
        },

        handleSelectAd(item) {
            this.selected_item = item
        }
    }
}
</script>
