import request from './_config'

export function getTiktokAdsPackages(channel) {
    return request({
        url: `/v1/system/traffic-package/user?channel=${channel}`,
        method: 'get'
    })
}

export async function getCurrentPackage(channel) {
    const response = await request({
        url: `https://api.atosa.asia/api/v1/store/license/single-channel?channel=${channel}`,
        method: 'get'
    })

    return response.data
}

export async function getTrialPackage() {
    try {
        await request({
            url: 'https://app.atosa.asia/api/service2/mobile/get_trial_package',
            method: 'get',
            params: { channel: 'tiktok_ads' }
        })
    } catch (e) {
        console.error(e)
    }
}
