<template>
    <custom-drawer
        size="1100px"
        :wrapper_closable="false"
        :confirm_to_close="false"
        :visible.sync="visible_in_line"
    >
        <template slot="title">
            <div class="flex items-center h-full pl-4 pr-8 space-x-4">
                <p class="font-semibold">
                    {{ $t('page.campaign.add_products') }}
                </p>

                <div class="!ml-auto"></div>

                <el-button
                    icon="el-icon-check"
                    type="primary"
                    size="small"
                    :disabled="multiple_selection.length === 0"
                    @click="handleChooseProducts"
                >
                    {{ $t('button.confirm') }}
                </el-button>
            </div>
        </template>
        <div class="px-4 py-8">
            <div class="component-box">
                <div class="flex items-center mb-2 space-x-2">
                    <label-outside-input-select
                        :label="$t('page.campaign.spu_id_or_product_name')"
                    >
                        <el-input
                            v-model="search_spu_id"
                            type="text"
                            size="small"
                            class="w-[240px]"
                            :clearable="true"
                            :placeholder="$t('input.placeholder.please_input')"
                            @clear="findSpuId"
                        >
                            <el-button
                                slot="append"
                                icon="el-icon-search"
                                @click="findSpuId"
                            ></el-button>
                        </el-input>
                    </label-outside-input-select>

                    <div class="!ml-auto"></div>
                    <el-button
                        icon="el-icon-refresh"
                        size="small"
                        @click="fetchProductCatalogs()"
                    ></el-button>
                    <el-button
                        :type="
                            multiple_selection.length === data_list.length &&
                            data_list.length > 1
                                ? 'danger'
                                : ''
                        "
                        size="small"
                        @click="handleSelectAll"
                    >
                        {{
                            multiple_selection.length === data_list.length
                                ? $t('common.deselect_all')
                                : $t('button.select_all')
                        }}</el-button>
                </div>

                <el-table
                    v-loading="m__loading"
                    height="640"
                    :data="data_list"
                    @sort-change="handleSortChange"
                >
                    <el-table-column width="320" :label="$t('common.name')">
                        <template #default="scope">
                            <div class="flex space-x-2">
                                <img
                                    :src="scope.row.product_image_url"
                                    class="w-[60px] h-[60px] object-contain"
                                />
                                <div>
                                    <el-tooltip
                                        class="item"
                                        effect="dark"
                                        :content="scope.row.title"
                                        placement="top"
                                    >
                                        <p class="font-semibold">
                                            {{ scope.row.title }}
                                        </p>
                                    </el-tooltip>

                                    <p class="text-desc-text">
                                        SPU ID : {{ scope.row.item_group_id }}
                                    </p>

                                    <a
                                        v-if="
                                            scope.row.landing_page &&
                                            scope.row.landing_page.url
                                        "
                                        :href="scope.row.landing_page.url"
                                        target="_blank"
                                        class="text-desc-text underline"
                                    >
                                        {{ $t('button.see_details') }}
                                    </a>
                                </div>
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column
                        width="160"
                        :label="$t('common.price')"
                        :prop="'min_price'"
                        sortable="custom"
                    >
                        <template #default="scope">
                            <p
                                v-if="
                                    scope.row.price &&
                                    scope.row.price.sale_price
                                "
                            >
                                Sale:
                                {{
                                    p__renderMoneyByCurrency(
                                        scope.row.price.sale_price,
                                        scope.row.price.currency
                                    )
                                }}
                            </p>
                            <p v-else>
                                Sale:
                                {{
                                    p__renderMoneyByCurrency(
                                        scope.row.min_price,
                                        scope.row.currency
                                    )
                                }}
                            </p>
                            <p
                                v-if="scope.row.price_list"
                                class="text-desc-text"
                            >
                                {{
                                    showPrice(
                                        scope.row.price_list,
                                        scope.row.price.currency
                                    )
                                }}
                            </p>
                        </template>
                    </el-table-column>

                    <el-table-column
                        min-width="120"
                        :label="$t('common.description')"
                    >
                        <template #default="scope">
                            <p
                                v-if="scope.row.description"
                                class="util-max-2-text-lines"
                            >
                                {{ scope.row.description }}
                            </p>
                        </template>
                    </el-table-column>

                    <el-table-column :label="$t('common.category')">
                        <template #default="scope">
                            <p>
                                {{ scope.row.category }}
                            </p>
                        </template>
                    </el-table-column>

                    <el-table-column
                        width="120"
                        :label="$t('page.campaign.historical_sales')"
                        :prop="'historical_sales'"
                        sortable="custom"
                    >
                        <template #default="scope">
                            <p>
                                {{ scope.row.historical_sales }}
                            </p>
                        </template>
                    </el-table-column>

                    <el-table-column width="120" :label="$t('common.option')">
                        <template #default="scope">
                            <el-button
                                v-if="
                                    multiple_selection.some(
                                        (item) =>
                                            item.item_group_id ===
                                            scope.row.item_group_id
                                    )
                                "
                                size="small"
                                type="danger"
                                @click="handleChooseProduct(scope.row)"
                            >
                                {{ $t('common.unchecked') }}
                            </el-button>
                            <el-button
                                v-else
                                size="small"
                                :disabled="scope.row.stocks === 0"
                                @click="handleChooseProduct(scope.row)"
                            >
                                {{ $t('button.choose') }}
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <p class="mt-2 text-sm">
                    {{ multiple_selection.length }} / {{ max_products }}
                    {{ $t('common.selected') }}
                </p>
            </div>
        </div>
    </custom-drawer>
</template>

<script>
import paginationDataMixin from '@/plugins/mixins/pagination-data'
import { getProductCatalog } from '@/services/atosa-tiktok-ads/catalog'
import { getProductsInTiktokShop } from '@/services/atosa-tiktok-ads/store'

export default {
    mixins: [paginationDataMixin],

    props: [
        'visible',
        'advertiser_id',
        'selected_products',
        'belong_to_atosa',
        'tiktok_account_id',
        'catalog_id',
        'currency',
        'store_id',
        'store_authorized_bc_id',
        'bc_id',
        'max_products'
    ],

    data() {
        return {
            search_spu_id: '',
            multiple_selection: [],
            data_list: [],
            sort_field: 'historical_sales',
            sort_type: 'DESC'
        }
    },

    computed: {
        visible_in_line: {
            get: function () {
                return this.visible
            },
            set: function (val) {
                this.$emit('update:visible', val)
            }
        }
    },

    watch: {
        visible_in_line() {
            if (this.visible_in_line) {
                this.multiple_selection = [...this.selected_products]
            }
        },

        store_authorized_bc_id() {
            this.fetchProductCatalogs()
        },

        store_id() {
            this.fetchProductCatalogs()
        }
    },

    mounted() {
        this.fetchProductCatalogs()
    },

    methods: {
        findSpuId() {
            if (this.search_spu_id) {
                const temp_search = this.search_spu_id.trim()

                this.data_list = this.m__data_for_pagination.filter(
                    (item) =>
                        item.item_group_id === temp_search ||
                        item.title.match(new RegExp(temp_search, 'i'))
                )

                return
            }

            this.data_list = this.m__data_for_pagination
        },

        showPrice(price_list, currency) {
            if (price_list.length === 0) {
                return this.p__renderMoneyByCurrency(0, currency)
            }

            if (price_list.length === 1) {
                return this.p__renderMoneyByCurrency(price_list[0], currency)
            }

            const min = Math.min(...price_list)
            const max = Math.max(...price_list)
            if (min === max) {
                return this.p__renderMoneyByCurrency(min, currency)
            }
            return `${this.p__renderMoneyByCurrency(
                min,
                currency
            )} - ${this.p__renderMoneyByCurrency(max, currency)}`
        },

        async fetchProductCatalogs() {
            if (!this.store_id) {
                return
            }

            this.m__loading = true

            const temp_prods = []
            let page = 1

            try {
                const response = await getProductsInTiktokShop(
                    this.tiktok_account_id,
                    {
                        bc_id: this.store_authorized_bc_id,
                        belong_to_atosa: this.belong_to_atosa,
                        store_id: this.store_id,
                        page,
                        page_size: 100,
                        sort_type: this.sort_type,
                        sort_field: this.sort_field
                    }
                )
                if (response.code === 0) {
                    temp_prods.push(...response.data.store_products)

                    while (page < response.data.page_info.total_page) {
                        page++

                        const response = await getProductsInTiktokShop(
                            this.tiktok_account_id,
                            {
                                bc_id: this.store_authorized_bc_id,
                                belong_to_atosa: this.belong_to_atosa,
                                store_id: this.store_id,
                                page,
                                page_size: 100,
                                sort_type: this.sort_type,
                                sort_field: this.sort_field
                            }
                        )

                        if (response.code === 0) {
                            temp_prods.push(...response.data.store_products)
                        }
                    }
                }
            } catch (error) {
                console.error(error)
            }

            const result = []
            let page_number = 1

            try {
                const response = await getProductCatalog(
                    this.tiktok_account_id,
                    {
                        bc_id: this.bc_id,
                        belong_to_atosa: this.belong_to_atosa,
                        catalog_id: this.catalog_id,
                        page_number,
                        page_size: 500
                    }
                )

                if (response.code === 0) {
                    result.push(...response.data.list)

                    while (page_number < response.data.page_info.total_page) {
                        page_number++

                        const response = await getProductCatalog(
                            this.tiktok_account_id,
                            {
                                bc_id: this.bc_id,
                                belong_to_atosa: this.belong_to_atosa,
                                catalog_id: this.catalog_id,
                                page_number,
                                page_size: 500
                            }
                        )

                        if (response.code === 0) {
                            result.push(...response.data.list)
                        }
                    }
                }
            } catch (error) {
                console.error(error)
            }

            const temp = result.filter(
                (item) => item.ad_creation_eligible === 'AVAILABLE'
            )

            const temp_m__data_for_pagination = []

            for (const iterator of temp) {
                if (
                    temp_m__data_for_pagination.some(
                        (item) => item.item_group_id === iterator.item_group_id
                    )
                ) {
                    const prod = temp_m__data_for_pagination.find(
                        (item) => item.item_group_id === iterator.item_group_id
                    )
                    if (prod) {
                        prod.price_list.push(iterator.price.price)
                    }
                } else {
                    temp_m__data_for_pagination.push({
                        ...iterator,
                        price_list: [iterator.price.price]
                    })
                }
            }

            const final_temp = []

            temp_prods.forEach((item) => {
                if (item.status === 'NOT_AVAILABLE') {
                    return
                }

                const finded_item = temp_m__data_for_pagination.find(
                    (it) => it.item_group_id === item.item_group_id
                )

                if (finded_item) {
                    final_temp.push({
                        ...finded_item,
                        ...item
                    })
                } else {
                    final_temp.push(item)
                }
            })

            this.m__data_for_pagination = final_temp

            this.p__event_bus.$emit('eb_all_products_for_preview_ad', result)

            this.findSpuId()

            this.m__loading = false
        },

        handleSelectAll() {
            if (this.multiple_selection.length === this.data_list.length) {
                this.multiple_selection = []
            } else {
                this.multiple_selection = [...this.data_list].slice(
                    0,
                    this.max_products
                )
            }
        },

        handleSortChange({ column, prop, order }) {
            this.sort_field = prop
            this.sort_type = order === 'descending' ? 'DESC' : 'ASC'

            this.fetchProductCatalogs()
        },

        handleChooseProducts() {
            this.p__event_bus.$emit(
                'eb_select_products',
                this.multiple_selection
            )

            this.visible_in_line = false
        },

        handleChooseProduct(product) {
            if (this.multiple_selection.length === this.max_products) {
                return
            }

            if (
                this.multiple_selection.some(
                    (item) => item.item_group_id === product.item_group_id
                )
            ) {
                this.multiple_selection = this.multiple_selection.filter(
                    (item) => item.item_group_id !== product.item_group_id
                )
            } else {
                this.multiple_selection.push(product)
            }
        }
    }
}
</script>
