import i18n from '@/plugins/language'

export const p__trend_basic_chart_options = [
    {
        label: i18n.t(`common.cost`),
        value: 'spend',
        color: '#1abc9c'
    },
    {
        label: 'CPC',
        value: 'cpc',
        color: '#3955f6'
    },
    {
        label: i18n.t('common.xxx_has_plural_noun', {
            xxx: i18n.t(`common.impression`)
        }),
        value: 'impressions',
        color: '#06d6db'
    },
    {
        label: 'CPM',
        value: 'cpm',
        color: '#f1c40f'
    },
    {
        label: 'CPA',
        value: 'cost_per_conversion',
        color: '#e84118'
    },
    {
        label: i18n.t('common.xxx_has_plural_noun', {
            xxx: i18n.t(`common.click`)
        }),
        value: 'clicks',
        color: '#feba69'
    },
    {
        label: 'CVR',
        value: 'conversion_rate',
        color: '#00a8ff'
    },
    {
        label: 'CTR',
        value: 'ctr',
        color: '#f368e0'
    },
    {
        label: i18n.t('common.xxx_has_plural_noun', {
            xxx: i18n.t(`common.conversion`)
        }),
        value: 'conversion',
        color: '#fe6b88'
    }
]

export const p__trend_real_time_chart_options = [
    {
        label: i18n.t(`page.campaign.column_real_time_cost_per_conversion`),
        value: 'real_time_cost_per_conversion',
        color: '#ffbe76'
    },
    {
        label: i18n.t(`page.campaign.column_real_time_conversion_rate`),
        value: 'real_time_conversion_rate',
        color: '#686de0'
    },
    {
        label: i18n.t(`page.campaign.column_real_time_result`),
        value: 'real_time_result',
        color: '#6ab04c'
    },
    {
        label: i18n.t(`page.campaign.column_real_time_cost_per_result`),
        value: 'real_time_cost_per_result',
        color: '#130f40'
    },
    {
        label: i18n.t(`page.campaign.column_real_time_result_rate`),
        value: 'real_time_result_rate',
        color: '#ffbe76'
    }
]

export const p__trend_result_chart_options = [
    {
        label: i18n.t(`page.campaign.column_result`),
        value: 'result',
        color: '#8c7ae6'
    },
    {
        label: i18n.t(`page.campaign.column_cost_per_result`),
        value: 'cost_per_result',
        color: '#e84118'
    },
    {
        label: i18n.t(`page.campaign.column_result_rate`),
        value: 'result_rate',
        color: '#6049a5'
    },
    {
        label: i18n.t(`page.campaign.column_real_time_conversion`),
        value: 'real_time_conversion',
        color: '#44bd32'
    }
]

export const p__trend_video_play_chart_options = [
    {
        label: i18n.t(`page.campaign.column_video_play_actions`),
        value: 'video_play_actions',
        color: '#9c88ff'
    },
    {
        label: i18n.t(`page.campaign.column_video_watched_2s`),
        value: 'video_watched_2s',
        color: '#192a56'
    },
    {
        label: i18n.t(`page.campaign.column_video_watched_6s`),
        value: 'video_watched_6s',
        color: '#ffa502'
    },
    {
        label: i18n.t(`page.campaign.column_video_views_p25`),
        value: 'video_views_p25',
        color: '#1e90ff'
    },
    {
        label: i18n.t(`page.campaign.column_video_views_p50`),
        value: 'video_views_p50',
        color: '#F97F51'
    },
    {
        label: i18n.t(`page.campaign.column_video_views_p75`),
        value: 'video_views_p75',
        color: '#55E6C1'
    },
    {
        label: i18n.t(`page.campaign.column_video_views_p100`),
        value: 'video_views_p100',
        color: '#6D214F'
    },
    {
        label: i18n.t(`page.campaign.column_average_video_play`),
        value: 'average_video_play',
        color: '#57606f'
    },
    {
        label: i18n.t(`page.campaign.column_average_video_play_per_user`),
        value: 'average_video_play_per_user',
        color: '#57606f'
    }
]

export const p__trend_onsite_options = [
    {
        label: i18n.t(`page.campaign.column_onsite_shopping`),
        value: 'onsite_shopping',
        color: '#ffbe76'
    },
    {
        label: i18n.t(`page.campaign.column_onsite_initiate_checkout_count`),
        value: 'onsite_initiate_checkout_count',
        color: '#686de0'
    },
    {
        label: i18n.t(`page.campaign.column_onsite_on_web_detail`),
        value: 'onsite_on_web_detail',
        color: '#6ab04c'
    },
    {
        label: i18n.t(`page.campaign.column_total_onsite_shopping_value`),
        value: 'total_onsite_shopping_value',
        color: '#130f40'
    },
    {
        label: i18n.t(`page.campaign.column_onsite_shopping_roas`),
        value: 'onsite_shopping_roas',
        color: '#ffbe76'
    },
    {
        label: i18n.t(`page.campaign.column_live_views`),
        value: 'live_views',
        color: '#ffbe76'
    }
]

export const p__basic_metrics = p__trend_basic_chart_options.map(
    (item) => item.value
)

export const p__view_data_metric_options = [
    {
        label: i18n.t('common.basic'),
        options: p__trend_basic_chart_options
    },
    {
        label: i18n.t('common.result'),
        options: p__trend_result_chart_options
    },
    {
        label: i18n.t('common.real_time'),
        options: p__trend_real_time_chart_options
    },
    {
        label: i18n.t('common.video_play'),
        options: p__trend_video_play_chart_options
    }
]

export const p__trend_chart_options_for_audience = [
    ...p__trend_basic_chart_options,
    ...p__trend_result_chart_options,
    ...p__trend_real_time_chart_options,
    ...p__trend_video_play_chart_options
    // ...p__trend_onsite_options
]

export const p__trend_chart_options_2 = [
    ...p__trend_chart_options_for_audience,
    ...p__trend_onsite_options
]

export const p__getNameByTrend = (value) => {
    const trend = p__trend_chart_options_2.find((item) => item.value === value)

    if (trend) {
        return trend.label
    }
    return ''
}
